@import (reference) "/assets/css/widgets/calendar-toolbar-navigation";

 .room-rates-page {
  .calendar-toolbar {
    display: grid;
    grid-template-areas:
      "dates navigation"
      "pages actions";
    grid-template-rows: auto 1fr;
    column-gap: 8px;

    .calendar-toolbar__dates {
      grid-area: dates;
      display: inline-flex;
      align-self: start;

      input#set_date {
        min-width: 100px;
        max-width: 150px;
      }
    }

    .calendar-toolbar__pages {
      grid-area: pages;
      display: flex;
      flex-wrap: wrap;
      align-items: start;
      gap: 8px
    }

    .calendar-toolbar__actions {
      grid-area: actions;
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      gap: 8px;

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
        align-items: start;
        gap: 8px;
      }
    }

    .calendar-toolbar-navigation {
      grid-area: navigation;
    }
  }

  @media (min-width: 550px) {
    .calendar-toolbar {
      grid-template-areas:
        "dates navigation"
        "pages actions";
      grid-template-columns: minmax(150px, 220px) 1fr;
    }
  }

  @media (min-width: 1400px) {
    .form-group {
      margin-bottom: 0;
    }

    .calendar-toolbar {
      grid-template: "dates pages actions navigation";
      grid-template-columns: minmax(150px, 250px) auto 1fr max-content;
    }
  }
}

div#dp {
  .cell_occupancy {
    color: @text-light;
    font-weight: 700;
    background-color: @brand-primary !important;
  }
}

#heatmapLegend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 8px;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    gap: 8px;

    > span {
      width: 12px;
      height: 12px;
      border: 1px solid @border-gray;
      border-radius: @border-radius-small;
    }
  }
}
