#manage_service {
  .manage-service__main-content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    #appendtable {
      thead tr th:last-child,
      tbody tr td:last-child{
        width: 0;
        padding: 0 5px 0 0;
      }
    }
  }

  .manage-service__type-selection {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;

    .manage-service__type-selection-title {
      white-space: nowrap;
    }
  }

  .tab-pane {
    .manage-service__cells-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;

      .fc-event {
        display: flex;
        justify-content: center;
        align-items: start;
        padding: 8px;
        border-radius: @border-radius-small;
        text-align: center;
        cursor: pointer;

        .manage-service__cell-image {
          width: 32px;
          height: 32px;
          margin: 0 auto 4px;
        }

        h5 {
          min-height: 32px;
          margin: 0;
          word-break: break-word;
        }
      }

      @media (min-width: @screen-sm-min) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  #direct_add_product_additional {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 12px;
  }

  @media (min-width: @screen-sm-min) {
    #direct_add_product_additional {
      flex-direction: row;
      align-items: end;
      gap: 8px;
      width: 100%;

      > .form-group {
        margin-bottom: 0;
      }

      #additional_product_service[value=""] {
        color: red;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    .manage-service__main-content {
      flex-direction: row;

      > * {
        flex: 1;
      }
    }
  }
}
