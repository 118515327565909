// Mostly hijacked from bootstrap5, with some changes:
// * Use bs3 variables (@grid-gutter-width)
// * Use bs3 grid breakpoints
// @see https://getbootstrap.com/docs/5.2/layout/css-grid/

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, floor(@grid-gutter-width / 2));
}

each(range(12), {
  .grid .g-col-@{value} {
    grid-column: auto/span @value;
  }
});

// Cannot start after last column
each(range(12-1), {
  .grid .g-start-@{value} {
    grid-column-start: @value;
  }
});

@media (min-width: @screen-xs-min) {
  each(range(12), {
    .grid .g-col-xs-@{value} {
      grid-column: auto/span @value;
    }
  });

  // Cannot start after last column
  each(range(12-1), {
    .grid .g-start-xs-@{value} {
      grid-column-start: @value;
    }
  });
}

@media (min-width: @screen-sm-min) {
  each(range(12), {
    .grid .g-col-sm-@{value} {
      grid-column: auto/span @value;
    }
  });

  // Cannot start after last column
  each(range(12-1), {
    .grid .g-start-sm-@{value} {
      grid-column-start: @value;
    }
  });
}

@media (min-width: @screen-md-min) {
  each(range(12), {
    .grid .g-col-md-@{value} {
      grid-column: auto/span @value;
    }
  });

  // Cannot start after last column
  each(range(12-1), {
    .grid .g-start-md-@{value} {
      grid-column-start: @value;
    }
  });
}

@media (min-width: @screen-lg-min) {
  each(range(12), {
    .grid .g-col-lg-@{value} {
      grid-column: auto/span @value;
    }
  });

  // Cannot start after last column
  each(range(12-1), {
    .grid .g-start-lg-@{value} {
      grid-column-start: @value;
    }
  });
}

@media (min-width: @screen-xl-min) {
  each(range(12), {
    .grid .g-col-xl-@{value} {
      grid-column: auto/span @value;
    }
  });

  // Cannot start after last column
  each(range(12-1), {
    .grid .g-start-xl-@{value} {
      grid-column-start: @value;
    }
  });
}
