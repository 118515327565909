#preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  user-select: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 10% auto;
  height: 100%;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  .page-loading-spinner {
    height: 225px;
  }

  &.preload_add {
    background-color: #222429e6;
    position: fixed;
  }
}

div.kv-loader-overlay {
  opacity: 1;
  z-index: 1;

  .kv-loader {
    background: transparent url(/svg/inline_spinner_dark.svg) center center no-repeat;
    transform: translateX(-50%) translateY(-50%);
  }
}

.inline-loading-spinner {
  width: 20px;
  height: 20px;
  user-select: none;

  &.inline-loading-spinner--large {
    width: 32px;
    height: 32px;
  }

  &.inline-loading-spinner--xs {
    width: 12px;
    height: 12px;
  }
}

.box .overlay .inline-loading-spinner,
.modal .overlay .inline-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 32px;
  height: 32px;
}
