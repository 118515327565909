.pdf-wrapper {
  display: flex;
  flex-flow: column;
  height: 100vh;

  > nav.pdf-wrapper__navbar {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 12px;
    width: 100%;
    padding: 6px;
    background-color: @brand-gray;

    > .pdf-wrapper__navbar-options {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      > .pdf-wrapper__title {
        margin: auto 4px;
      }
    }

    > .pdf-wrapper__main-actions {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  > iframe {
    width: 100%;
    height: 100%;
    border-width: 0;
    flex: 1;
  }
}
