.room-pricing-block {
  .room-pricing__pack-price {
    margin-top: 8px;
  }

  .room-pricing__table {
    width: 100%;
    margin-top: 8px;
    border-collapse: collapse;

    td {
      max-width: 1px;
      border: 1px solid @border-gray;
    }

    .room-rate-week {
      width: 1%;
      max-width: 100px;
      border: none;
      border-right: 1px solid @border-gray;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px 8px;
        font-size: 10px;
        font-weight: 600;
      }
    }

    .room-rate-week-separator {
      height: 8px;
      border: none;
    }

    .room-rate-wrapper {
      > * {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
      }

      .room-rate-header {
        background-color: @brand-gray;
        text-align: center;
        font-weight: bold;
        color: @text-color;
      }

      .room_rate {
        border-top: 1px solid @border-gray;

        .room_rate_value {
          padding: 4px 8px;
          overflow: hidden;
        }

        .room_rate_textbox {
          display: none;
          width: calc(100% - 4px);
          height: 24px;
          margin: 2px;
          padding: 0;
          text-align: center;
          border-radius: @border-radius-base;
          border: 1px solid @border-gray;

          &:focus-visible {
            border: 1px solid @brand-primary;
            outline: none;
          }
        }
      }
    }

    .room-rate-summary {
      padding-top: 8px;
      border: none;
    }
  }
}
