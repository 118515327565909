#create_reservation {
  .create-reservation__room-required {
    display: flex;
    gap: 8px;

    > :first-child {
      flex: 1;
    }
  }

  .create-reservation__guests-types {
    display: flex;
    gap: 8px;

    > * {
      flex: 1;
    }
  }

  .create-reservation__arriving-guests {
    margin-top: 12px;
  }

  @media (min-width: 500px) {
    .create-reservation__group-1 {
      display: flex;
      align-items: start;
      gap: 8px;

      > * {
        flex: 1;
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    .create-reservation__group-2 {
      display: flex;
      align-items: start;
      gap: 8px;

      > * {
        flex: 1;
      }
    }

    .create-reservation__arrival-departure,
    .create-reservation__zip-city {
      > .form-group {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    .create-reservation__group-2.group-disabled-3 {
      display: block;
    }

    .create-reservation__group-3 {
      display: flex;
      align-items: start;
      gap: 12px;

      > * {
        flex: 1;
      }
    }

    .create-reservation__zip-city {
      > .form-group {
        margin-bottom: 12px;
      }
    }

    .create-reservation__room-required {
      > .form-group {
        margin-bottom: 0;
      }
    }

    .create-reservation__category {
      margin-bottom: 0;
    }

    .create-reservation__arriving-guests {
      margin-top: 0;
    }
  }
}
