.label {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: @padding-small-vertical 6px;
  border-radius: @border-radius-small;

  i.fa {
    font-size: 10px;
  }
}

.label-primary {
  border: 1px solid @text-light;
  background-color: @brand-primary;
  color: @text-light;
}

.label-ghost-primary {
  border: 1px solid @brand-primary;
  background-color: white;
  color: @text-color;
}

.label-ghost-success {
  .label-ghost-primary;
  color: @brand-success;
  border-color: @brand-success;
}

.label-ghost-danger {
  .label-ghost-primary;
  color: @brand-danger;
  border-color: @brand-danger;
}

.label-md {
  padding: @padding-base-vertical @padding-base-horizontal;
}
