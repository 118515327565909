.list-controls {
  display: grid;
  grid-template-areas:
          "all items-search"
          "from-to from-to"
          "month-year month-year";
  grid-template-columns: auto 1fr auto;
  gap: 8px;

  .list-controls__group {
    display: inline-flex;
    width: fit-content;
    padding: 8px;
    border: 1px solid @border-gray;
    border-radius: @border-radius-small;

    > * {
      align-self: center;
    }
  }

  .list-controls__all {
    grid-area: all;
  }

  .list-controls__from-to {
    grid-area: from-to;

    .form-group {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 0;
    }
  }

  .list-controls__month-year {
    grid-area: month-year;

    .form-group {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 0;
    }
  }

  .list-controls__items-search {
    grid-area: items-search;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-left: 8px;
  }

  @media (min-width: 400px) {
    grid-template-areas:
          "all items-search"
          "from-to from-to"
          "month-year month-year";
    grid-template-columns: auto 1fr auto;
  }

  @media (min-width: 1000px) {
    grid-template-areas:
          "all from-to items-search"
          "month-year month-year month-year";
    grid-template-columns: auto auto 1fr auto;
  }

  @media (min-width: 1250px) {
    grid-template-areas:
          "all from-to month-year items-search";
    grid-template-columns: auto auto auto 1fr auto;
  }
}
