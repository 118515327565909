.modal {
  user-select: none;
  z-index: 90;
}

.modal-backdrop {
  z-index: 80;
}

.model-dialog_cus {
  width: 100%;
  margin: 32px auto;

  @media (min-width: @screen-xs) {
    width: 90%;
  }

  @media (min-width: @screen-md) {
    width: 80%;
    max-width: 1100px;
  }
}

.modal-content {
  border-radius: @border-radius-large;
  user-select: text;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 12px;
  margin-left: 12px;
  margin-right: 12px;

  // Remove ::before and ::after elements so that flex + gap doesn't create extra space (reverts bootstrap clearfix)
  &:before, &:after {
    content: unset;
  }

  .close {
    margin: 0;

    &:first-child {
      margin-left: auto;
    }
  }
}

.modal-body {
  max-height: calc(100vh - 168px); // 168px = header height + footer height + 30px spacing top & bottom
  padding-left: 12px;
  padding-right: 12px;
  overflow-y: auto;
  overflow-x: hidden;

  &.modal-body--overflowing {
    max-height: unset;
    overflow: visible;
  }
}

.modal.bootbox {
  .modal-header {
    flex-direction: row-reverse;
  }
}

.modal-footer {
  margin-left: 12px;
  margin-right: 12px;
}
