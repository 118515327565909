.modal.bootstrap-dialog {
  .modal-header.bootstrap-dialog-header {
    padding-bottom: 6px;
    background-color: white;
    border-color: white;
    border-bottom: 1px solid @brand-gray;

    &:hover {
      background-color: white;
      border-color: white;
    }

    .bootstrap-dialog-title {
      font-weight: bold;
      color: @text-color;
      line-height: 1.42857143;
      font-size: 18px;
    }
  }
}
