@import (reference) "/assets/css/bootstrap/variables";

.reservation-header {
  display: flex;
  flex-direction: column;

  .reservation-header__details {
    flex-grow: 7;
    display: flex;
    flex-direction: column;
    align-items: start;

    #reservation_id {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 24px;

      .reservation-header__details-title {
        font-size: 10px;
      }
    }

    .channel_id {
      margin: 0;
      font-size: 16px;

      .channel-id__value {
        font-weight: 600;
      }
    }

    .created_by {
      font-size: 10px;
    }
  }

  .reservation-header__status-action {
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 12px;

    .reservation-status {
      gap: 8px;
      padding: 6px 20px;
      color: @text-light;
      font-size: 14px;
      text-transform: uppercase;
      cursor: default;

      &:hover {
        cursor: default;
      }
    }

    #status_word {
      margin: 0;
    }

    #status_button {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 480px) and (max-width: 991px), (min-width: 1350px) {
  .reservation-header {
    flex-direction: row;

    .reservation-header__status-action {
      margin-top: 0;
      align-items: end;
    }
  }
}
