.enquiry-form-page {
  .enquiry-form__header {
    h3 {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 24px;

      span {
        font-size: 10px;
      }
    }

    p {
      font-size: 10px;
    }
  }

  .field-enquiry-reservation_name {
    margin-bottom: 0;
    font-size: 14px;

    input {
      font-size: inherit;
    }
  }

  .enquiry-details__container {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    padding: 12px;
    border: 1px solid @border-gray;
    border-radius: @border-radius-small;
    font-size: 14px;

    .enquiry-details__booker-header,
    .enquiry-details__contact-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .enquiry-details__booker-address {
      margin-top: 8px;
      margin-bottom: 0;
      word-break: break-word;
    }

    .enquiry-details__booker-actions,
    .enquiry-booking__contact-actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      gap: 8px;
    }

    .enquiry-details__contact-selector {
      margin-top: 12px;
    }

    #contact-person,
    .js-contact-person {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      .contact-person__details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 8px;

        .contact-person__details-entry {
          display: flex;
          align-items: center;
          gap: 4px;

          i {
            width: 20px;
            height: 20px;
            padding: 0;
            font-size: 15px;
            text-align: center;
            color: @text-icon;
          }
        }
      }
    }
  }

  .enquiry-details__preferences {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    padding: 12px;
    border: 1px solid @border-gray;
    border-radius: @border-radius-base;
    font-size: 14px;
    font-weight: 700;
    word-break: break-word;

    .enquiry-details__client {
      margin: 0;

      .enquiry-details__client-preferences {
        font-weight: 400;
      }
    }

    #contactPreferences {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-weight: 400;
    }
  }

  @media (min-width: 600px) and (max-width: 991px), (min-width: 1600px) {
    .enquiry-details__container {
      flex-direction: row;

      .enquiry-details__booker {
        flex: 1 1 0;
      }
    }
  }
}
