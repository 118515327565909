.pagination {
  display: inline-flex;
  justify-content: start;
  gap: 4px;
  margin-top: 12px;

  li {
    border-radius: @border-radius-small;

    span, a {
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: @border-radius-small;
      color: @text-color;
      font-weight: 600;

      i.fa {
        color: @text-icon-active;
      }

      &:hover, &:focus {
        background-color: darken(white, 10%);

        &:active {
          background-color: darken(white, 10%);
        }
      }
    }

    &.disabled {
      span, a {
        &:hover {
          background-color: transparent;

          &:active {
            background-color: transparent;
          }
        }
      }
    }

    &.active {
      span , a {
        background-color: @brand-gray;
        color: @text-color;

        &:focus {
          color: @text-color;
          background-color: @brand-gray;
        }

        &:hover {
          color: @text-color;
          background-color: @brand-gray;

          &:active {
            color: @text-color;
            background-color: @brand-gray;
          }
        }
      }
    }
  }
}

ul.basic-pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0 0 0;
  padding: 0;
  list-style-type: none;

  li {
    button {
      white-space: nowrap;
      font-size: 14px;
    }
  }
}

.pagination-with-summary {
  display: flex;
  flex-direction: column-reverse;
  align-items: start;
  gap: 12px;

  ul.basic-pagination, ul.pagination {
    margin-top: 0;
  }

  > p, .table-summary, .summary {
    align-self: end;
    margin: 12px 0 0;
    font-size: 13px;
    font-style: italic;
  }

  @media (min-width: 600px) and (max-width: 992px), (min-width: 1200px) {
    flex-direction: row;
    justify-content: end;

    ul.basic-pagination, ul.pagination {
      margin-top: 12px;
      margin-right: auto;
    }

    > p, .table-summary, .summary {
      align-self: start;
    }
  }
}
