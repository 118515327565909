@import (reference) '/assets/css/common';
@import (reference) '/assets/css/buttons';

.nav-tabs > li.has-errors > a,
.nav-tabs > li.has-errors > a:hover,
.nav-tabs > li.has-errors > a:focus {
  color: @red;
  border: 1px solid @red;
  border-bottom-color: transparent;
}

.nav.nav-tabs {
  border-bottom: none;

  > li {
    > a {
      color: @text-color;
      font-weight: 600;
      border-bottom: none;

      &:hover {
        background-color: white;
        border-radius: @border-radius-small @border-radius-small 0 0;
        border-color: @border-gray;
        border-bottom: none;
        color: darken(@text-color, 10%);
      }

      &:focus {
        background-color: white;
      }
    }

    &.active > a {
      background-color: white;
      border-radius: @border-radius-small @border-radius-small 0 0;
      border-color: @border-gray;
      border-bottom: none;
      color: @text-color;
    }
  }
}

.nav.nav-tabs.nav-tabs-inverse {
  > li {
    > a {
      color: @text-light;

      &:hover {
        color: @text-color;
      }
    }

    &.active > a {
      color: @text-color;
    }
  }
}

.nav.nav-tabs.nav-tabs-divided {
  border-bottom: 1px solid @border-gray;
}

.box-header, .modal-body__header {
  .nav.nav-tabs {
    width: 100%;
    margin-top: 8px;
    border-bottom: 1px solid @border-gray;
    font-size: 14px;
    line-height: 24px;

    > li {
      > a {
        padding: 6px 12px;

        &:hover {
          margin-bottom: -1px;
          border-radius: 6px 6px 0 0;
        }
      }

      &.active {
        > a {
          border-radius: 6px 6px 0 0;
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }
  }
}

.nav.nav-tabs + .tab-content.tab-content-box {
  padding: 8px;
  border: 1px solid @border-gray;
  border-radius: 0 0 @border-radius-small @border-radius-small;
}

.nav.nav-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  // Remove ::before and ::after elements so that flex + gap doesn't create extra space (reverts bootstrap clearfix)
  &:before, &:after {
    content: unset;
  }

  > li {
    > a {
      .border-radius(@nav-pills-border-radius);

      margin-top: 0;
      margin-bottom: 0;
      background-color: @brand-gray;
      font-weight: 600;
      border-top: none;
      white-space: nowrap;
    }
  }

  > li.active {
    > a {
      background-color: @brand-primary;

      &:hover {
        background-color: darken(@brand-primary, 10%);
      }
    }
  }
}

#sidebar-editor .nav > li {
  > a.btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &.active > a.btn {
    color: @brand-secondary;

    &:focus, &:hover {
      color: @brand-secondary;
    }
  }
}
