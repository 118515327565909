#paymentElementsStub {
  max-width: 500px;
  margin: 0 auto;
  pointer-events: none;
}

#paymentElementsStub .input-group input {
  border-right: none;
}

#paymentElementsStub .input-group .input-group-addon {
  border-left: none;
}

#paymentElementsStub label {
  font-weight: normal;
}

#paymentElementsStub .tablist {
  display: flex;
  gap: 8px;
  color: rgb(109, 110, 120);
}

#paymentElementsStub .tablist button {
  background-color: white;
  flex: 1;
  border: 1px solid #e6e6e6;
}

#paymentElementsStub .tablist button:first-child {
  border: 2px solid var(--primaryColour);
}

#paymentElementsStub .tablist button:last-child {
  flex-grow: 0;
}

#paymentElementsStub .Tab {
  text-align: left;
  padding: 0.75em;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

#paymentElementsStub .TabIcon {
  height: 1.2em;
}

#checkoutButtonPreview {
  background-color: var(--primaryColour);
  border: none;
}
