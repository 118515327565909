.small-box {
  display: grid;
  grid-template-areas: "count inner icon"
                        "footer footer footer";
  grid-template-columns: auto 1fr auto;
  column-gap: 12px;
  height: 100%;
  margin-bottom: 0;
  padding: 16px 10px 10px;
  border-radius: @border-radius-large;
  background-color: @border-gray;
  color: @text-color;

  > .small-box__count {
    grid-area: count;
    min-width: 32px; // random value based on feeling when it looked good
    margin-left: 4px; // random value based on feeling when it looked good
    font-size: 32px; // random value based on feeling when it looked good
    line-height: 1;
    font-weight: 700;
    text-align: start;
  }

  > .inner {
    grid-area: inner;
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    text-align: start;

    h3 {
      margin-bottom: 6px;
      font-size: 24px; // random value based on feeling when it looked good
      white-space: normal;
      z-index: 1;
    }

    p {
      display: flex;
      gap: 8px;
      margin-bottom: 0;
      font-size: 14px;
      z-index: 1;
    }
  }

  > .icon {
    grid-area: icon;
    position: relative;
    inset: 0;
    display: flex;
    justify-content: end;
    height: 40px;
    width: 45px;
    margin-right: 4px; // random value based on feeling when it looked good
    color: @text-light;
    font-size: 40px;
  }

  > .small-box-footer {
    grid-area: footer;
    align-self: end;
    min-height: 30px;
    margin-top: 12px;
    border-radius: @border-radius-small;
    background-color: white;
    color: @text-color;
    font-weight: 600;
    z-index: 1;

    &:hover {
      background-color: darken(white, 5%);
      color: @text-color;
    }
  }

  &:hover {
    > .small-box__count {
      color: @text-color;
    }

    > .inner {
      h3, p {
        color: @text-color;
      }
    }

    .icon {
      font-size: 48px;
    }
  }
}

.small-box-auxiliary {
  position: absolute;
  top: 10px;
  right: 10px;
}
