@import (reference) "/assets/css/theme/variables";

.callout {
  margin-bottom: 12px;
  border-radius: @border-radius-base;
}

.callout.callout-info {
  background-color: lighten(@body-bg, 1%) !important;
  border-color: darken(@brand-gray, 10%);
  color: @text-color !important;
}

.callout a {
  color: @link-color;

  &:hover {
    color: @link-hover-color
  }
}
