.file-error-message {
  position: relative;
  margin: 0 0 12px;
  padding: 12px 40px 12px 12px;
  border: none;
  background-color: @brand-danger;
  color: @text-light;

  button.close {
    position: absolute;
    top: 8px;
    right: 8px;
    border: 1px solid @text-light;
    border-radius: @border-radius-small;
    color: @text-light;
    font-weight: bold;
    opacity: 1;

    &:hover {
      background-color: #FFFFFFAA;
    }
  }
}

.file-input {
  .file-caption-icon {
    z-index: 2;

    i.bi-file-earmark-arrow-up {
      font-style: normal;

      &:after {
        content: '\f574';
        font-family: 'Font Awesome 6 Pro';
      }
    }
  }

  .btn-file {
    i.bi-folder2-open {
      font-style: normal;

      &:after {
        content: '\f07c';
        font-family: 'Font Awesome 6 Pro';
      }
    }
  }
}

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}
