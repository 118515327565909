.email-template-page {
  #sidebar-wrapper {
    border-radius: @border-radius-base;
    z-index: 2;

    #sidebar .sidebar-items-divider {
      width: 1px;
      height: 80px;
      background-color: white;
    }
  }

  #sidebar-wrapper .sidebar-nav li a {
    color: @text-light;
    background: @brand-primary;

    &:hover {
      background: darken(@brand-primary, 5%);
    }

    &.active {
      background: darken(@brand-primary, 10%);
    }
  }

  #sidebar-opzioni {
    margin-top: 84px;
    border-radius: @border-radius-base;
    background-color: darken(@brand-gray, 10%);

    .lyrow .preview {
      border-radius: @border-radius-small;
    }

    .lyrow.dragitem:hover .preview {
      opacity: 0.85;
      border: 2px solid @brand-primary;
    }
  }

  .demo {
    .lyrow .remove.label i {
      font-size: 16px;
    }

    .lyrow .drag.label.label-ghost-primary {
      background-color: white;
      z-index: 1;
    }
  }
}
