.timeline > li {
  // The icons
  > .fa,
  > .glyphicon,
  > .ion {
    // Vertically align icon with text
    top: 4px;
  }
}

.timeline:before {
  left: 32px;
  width: 2px;
}

.timeline > li > .timeline-item > .timeline-header {
  font-size: 14px;
  color: @text-color;
}
