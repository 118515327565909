@import (reference) "/assets/css/widgets/chip";

.react-select-container {
  .react-select__control {
    border-radius: @border-radius-base;
    min-height: @input-height-base;
    border-color: @border-gray;
    box-shadow: unset;

    &:hover {
      border-color: @border-gray;
    }
  }

  .react-select__value-container {
    padding: 4px 0 4px 6px;
  }

  .react-select__multi-value {
    .chip;
    position: relative;
    min-height: 24px;
    margin: 0 4px 0 0;
    padding: 4px 32px 4px 6px;
    font-weight: 700;
  }

  .react-select__multi-value__label {
    padding: 0;
    font-size: 100%;
  }

  .react-select__multi-value__remove {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0 ;
    padding: 0;

    &:before {
      content: '\f057';
      font-family: "Font Awesome 6 Pro";
      position: relative;
      right: 22px;
      font-size: 16px;
      opacity: 0.5;
      cursor: pointer;
    }

    &:hover:before {
      opacity: 1;
      color: @text-color;
    }
  }

  .react-select__input-container {
    margin: 0;
    color: @text-color;
  }

  .react-select__indicator {
    padding: calc((@input-height-base - 2px - 20px) / 2); // Icon is 20px. Adding padding bumps height to match input's height minus border.
    color: lighten(@brand-primary, 50%);

    &:hover {
      color: @brand-primary;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option {
    color: @text-color;

    &.react-select__option--is-selected {
      background: lighten(@brand-gray, 2%);
    }

    &:hover, &.react-select__option--is-focused {
      background: @brand-gray;
    }
  }
}

.form-group.has-error {
  .react-select-container {
    .react-select__control {
      border-color: @brand-danger;
    }
  }
}
