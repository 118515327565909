.client-form-page {
  .client-form__general-info {
    @media (min-width: @screen-md-min) {
      display: flex;
      gap: 12px;
      align-items: stretch;

      > div {
        flex: 1 1 0;
      }
    }

    .client-form__details-contact-wrapper {
      display: flex;
      flex-direction: column;

      > div.box{
        flex-grow: 1;
      }

      .contact-card {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .contact-card__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 12px;

          .contact-card__title {
            font-weight: 700;
          }

          .contact-card__actions {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          }
        }

        .contact-card__body {
          > div {
            display: flex;
            align-items: center;
            gap: 8px;

            i.fa {
              min-width: 14px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .client-form__gdpr-body {
    @media (min-width: @screen-md-min) {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .form-group {
        margin-bottom: 0;
      }
    }
  }
}
