@import (reference) "/assets/css/bootstrap/variables";

.field-reservation-reservation_name {
  margin-bottom: 0;
  font-size: 14px;

  input {
    font-size: inherit;
  }
}

.reservation-details__container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 12px;
  border: 1px solid @border-gray;
  border-radius: @border-radius-small;
  font-size: 14px;

  .reservation-details__booker-address,
  .reservation-details__billing-address {
    margin-top: 8px;
    margin-bottom: 0;
    word-break: break-word;
  }

  .reservation-details__contact-selector {
    margin-top: 12px;
  }

  #contact-person,
  .js-contact-person {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .contact-person__details {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-top: 8px;

      .contact-person__details-entry {
        display: flex;
        align-items: center;
        gap: 4px;

        i {
          width: 20px;
          height: 20px;
          padding: 0;
          font-size: 15px;
          text-align: center;
          color: @text-icon;
        }
      }
    }
  }

  .reservation-details__billing {
    margin-top: 16px;
    padding-top: 16px;

    &.reservation-details__billing--seperated {
      border-top: 1px solid @border-gray;
    }
  }
}

.reservation-details__preferences {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  padding: 12px;
  border: 1px solid @border-gray;
  border-radius: @border-radius-base;
  font-size: 14px;
  font-weight: 700;
  word-break: break-word;

  .reservation-details__client {
    margin: 0;

    .reservation-details__client-preferences {
      font-weight: 400;
    }
  }

  #contactPreferences {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
  }
}

@media (min-width: 600px) and (max-width: 991px), (min-width: 1600px) {
  .reservation-details__container {
    flex-direction: row;

    .reservation-details__booker {
      flex: 1 1 0%;
    }

    .reservation-details__billing {
      flex: 1 1 0%;
      margin-top: 0;
      margin-left: 16px;
      padding-top: 0;
      padding-left: 16px;

      .reservation-details__billing-add {
        display: flex;
        justify-content: end;
      }

      &.reservation-details__billing--seperated {
        border-top: none;
        border-left: 1px solid @border-gray;
      }
    }
  }
}
