.status-indicator {
  display: inline-flex;
  height: 20px;
  width: 20px;
  border: 1px solid darken(@border-gray, 5%);
  border-radius: @border-radius-small;
}

.status-indicator--icon {
  text-align: center;
  font-size: 16px;
  line-height: 1;
}

.status-indicator-sm {
  height: 12px;
  width: 12px;
}

.status-indicator--invoiced {
  height: 20px;
  width: 3px;
  background-color: @brand-danger;
  border-radius: @border-radius-small;
}
