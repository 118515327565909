.calendar-toolbar-navigation {
  .btn-group {
    display: flex;
    justify-content: end;
    width: 100%;

    button {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 75px;
    }
  }

  @media (min-width: 767px) {
    .btn-group {
      justify-content: end;

      button {
        max-width: 50px;
        flex-grow: unset;
        flex-basis: unset;
      }
    }
  }
}
