.email-settings-page {
  .email-settings__service-provider {
    text-align: center;
    padding: 16px 20px;
    border: 1px solid @border-gray;
    border-radius: @border-radius-large;
    cursor: pointer;

    .email-settings__service-provider-logo {
      display: block;
      height: 24px;
    }
  }
}
