@import (reference) "bootstrap/variables";
@import (reference) "~bootstrap/less/mixins/grid-framework";

.make-xl-grid-columns() {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) { // initial
    @item: ~".col-xl-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-xl-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-right: floor((@grid-gutter-width / 2));
      padding-left: ceil((@grid-gutter-width / 2));
    }
  }
  .col(1); // kickstart it
}

// Columns
//
// Common styles for xl grid columns

.make-xl-grid-columns();


// XL grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-xl-min) {
  .make-grid(xl);
}
