.well {
  min-height: unset;
  margin-bottom: 12px;
  padding: 12px;
  border: none;
}

.well-sm {
  padding: 8px;
}

.well-lg {
  padding: 20px;
}
