.general-overview__datepicker {
  min-height: auto;
}

.general-overview__range-block {
  @media (min-width: @screen-md-min) {
    display: flex;
    column-gap: 12px;

    > .box {
      width: calc(50% - 6px);
    }
  }
}
