@import (reference) "/assets/css/variables";
@import (reference) "/assets/css/button-groups";

fieldset {
  margin-bottom: 12px;
  padding: 10px;
  border: 1px solid @border-gray;
  border-radius: @border-radius-base;

  legend {
    display: flex;
    align-items: center;
    gap: 4px;
    width: auto;
    margin-bottom: 0;
    padding-left: 6px;
    padding-right: 6px;
    border: none;
    font-size: 18px;
    color: @text-color;
    line-height: 1;
  }
}

label {
  margin-bottom: 0;
  font-weight: 400;
}

.form-control {
  border-color: @border-gray;

  &:focus {
    border-color: darken(@border-gray, 10%);
  }
}

.input-checkbox {
  left: 0;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: 20px;
  height: 20px;
  border: 1px solid @border-gray;
  border-radius: @border-radius-base;
  cursor: pointer;

  &:checked, &:indeterminate {
    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: @text-light;
      font-size: 11px;
      font-family: "Font Awesome 6 Pro";
    }

    &:focus {
      outline: white solid 1px;
      outline-offset: -3px;
    }
  }

  &:checked {
    border-color: @brand-primary;
    background-color: @brand-primary;

    &:before {
      content: '\f00c';
      font-weight: bold;
    }
  }

  &:indeterminate {
    background-color: @border-gray;
    border-color: @border-gray;

    &:before {
      content: '\f068';
      color: @brand-primary;
    }
  }

  &[disabled="disabled"], &[disabled] {
    cursor: not-allowed;

    &:not(:checked) {
      background-color: @element-disabled;
    }

    &:checked {
      opacity: 0.5;
    }
  }
}

.input-radio {
  left: 0;
  appearance: none;
  -webkit-appearance: none;
  width: 19px;
  height: 19px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 0 1px @border-gray;
  background-color: white;

  &:checked {
    background: @brand-primary;
    box-shadow: 0 0 0 1px @brand-primary;
  }

  &:focus {
    outline: @brand-primary solid 1px;
    outline-offset: -1px;
  }

  &[disabled="disabled"], &[disabled] {
    &:not(:checked) {
      border-color: @element-disabled;
      background-color: @element-disabled;
    }

    &:checked{
      opacity: 0.5;
    }
  }
}

input[type="checkbox"] {
  .input-checkbox;
}

.checkbox, .radio {
  margin-top: 0;
  margin-bottom: 12px;

  label {
    min-height: 20px;
    padding-left: 28px;

    input[type="checkbox"] {
      .input-checkbox;
    }

    input[type="radio"] {
      .input-radio;
    }
  }
}

.radio.radio-inverse {
  label {
    input[type="radio"] {
      border: 3px solid @brand-primary;
      background: @brand-primary;
      box-shadow: 0 0 0 1px white;

      &:checked {
        background: white;
        box-shadow: 0 0 0 1px white;

        &:focus {
          outline-color: white;
        }
      }

      &:focus {
        outline: white solid 2px;
      }

      &[disabled] {
        border-color: @element-disabled;
      }
    }
  }
}

.checkbox-inline, .radio-inline {
  min-height: 20px;
  padding-left: 28px;

  input[type="checkbox"] {
    .input-checkbox;
  }

  input[type="radio"] {
    .input-radio;
  }
}

.checkbox-empty-label, .radio-empty-label {
  padding-left: 20px;
}


.checkbox-row {
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;

  > .form-group {
    margin-bottom: 0;
  }

  .checkbox + .checkbox {
      margin-top: 0;
  }
}

.radio-inline ~ .radio-inline {
  margin-left: 0;
}

.radio-group, .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 8px;

  .checkbox-inline ~ .checkbox-inline,
  .radio-inline ~ .radio-inline {
    margin-left: 0;
  }
}

select.form-control, .select-custom.form-control {
  padding-left: 10px;
  padding-right: 34px;
  cursor: pointer;
  appearance: none;
  background-image: url('/assets/svg/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 14px;

  &.text-light {
    background-image: url('/assets/svg/chevron-down-light.svg');

    > option {
      color: @text-color;
    }
  }

  &.select-opened {
    background-image: unset;
  }
}

.form-group {
  > label {
    margin-bottom: 4px;
    font-size: 10px;
    font-weight: 600;
  }

  > i.more-info {
    color: @text-icon;
    font-size: 12px;
  }

  .form-control {
    font-weight: 700;
    color: @text-color;

    &::placeholder {
      color: #A7AEC9;
    }

    &[disabled], &[disabled] + .input-group-addon {
      background-color: @element-disabled;
      cursor: not-allowed;
    }
  }

  .form-control ~ .input-group-addon {
    border-left: 0;
  }

  input[type="number"].form-control {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin-right: -10px;
    }
    // Hide spin button in Firefox, since it doesn't support styling the spin button
    -moz-appearance: textfield;
  }

  label ~ .checkbox {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;

    input[type="checkbox"] {
      .input-checkbox;
    }
  }

  label ~ .checkbox-inline {
    margin-left: 8px;
  }

  .bootstrap-switch {
    margin-right: 6px;
  }

  &.has-error {
    .form-control ~ .input-group-addon {
      border-right-color: @brand-danger;
    }
  }
}

.form-inline {
  .form-group {
    display: flex;
    align-items: center;
    gap: 8px;

    label {
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
    }

    .form-control {
      display: inline-block;
    }
  }

  @media (min-width: @screen-sm) {
    display: flex;
    gap: 8px;
    align-items: start;
    flex-wrap: wrap;

    .form-group {
      display: inline-flex;
    }
  }
}

.form-inline.form-inline-vertical {
  .form-group {
    display: block;

    label {
      font-size: 10px;
      margin-bottom: 4px;
      vertical-align: baseline;
    }
  }

  .btn {
    align-self: end;
  }

  @media (min-width: @screen-sm) {
    .form-group {
      > .form-control {
        display: block;
      }

      > .input-group {
        display: table;
      }
    }
  }
}

.form-group {
  .form-inline {
    .form-control {
      margin-bottom: 12px;

      @media (min-width: @screen-sm-min) {
        margin-bottom: 0;
      }
    }
  }
}

.form-control-static-btn {
  &.btn-sm {
    margin-top: (@input-height-base - @btn-sm-height-base) / 2;
  }
}

.form-email-action-group {
  display: flex;
  width: 100%;
  margin-top: 8px;

  > * {
    float: none;
  }

  .email-container {
    position: relative;
    display: flex;
    width: 100%;

    > input[type="email"] {
      width: 100%;
      height: 40px;
      border-radius: @border-radius-base 0 0 @border-radius-base;
      border-right: none;
    }

    > button.dropdown-toggle {
      height: 40px;
      border: 1px solid @border-gray;
      border-left: none;
      border-radius: 0;
    }
  }

  > button {
    height: 40px;
    border: 1px solid @border-gray;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-transform: capitalize;
  }
}

// Used as description text
.hint-block {
  margin: 4px 0 0;
  font-size: 12px;
  color: lighten(@text-color, 25%)
}

input[type="checkbox"] ~ .hint-block,
input[type="radio"] ~ .hint-block {
  margin-top: 0;
}

// Used as error text
.help-block,
.has-error .help-block { // overrides activeform selector: .kv-form-bs3 .help-block-error
  margin: 4px 0 0;
  font-size: 12px;
}

.help-block:empty {
  // Removes potentially unneeded spacing when there's no content
  display: none;
}

.table td > .form-group {
  // For fields inside a table, there's already spacing between rows/cells
  margin: 0;
}

.form-sm {
  .form-group {
    margin-bottom: @padding-small-vertical;
  }

  .form-control {
    .input-size(@input-height-small; @padding-small-vertical; @padding-small-horizontal; @font-size-base; @line-height-small; @input-border-radius-small);
  }

  textarea.form-control, select[multiple].form-control {
    height: unset; // Prefer "rows" over height
  }
}

.input-with-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-actions {
  .btn-ribbon;
  margin-top: 20px;
}

@media (min-width: @screen-sm-min) {
  .form-horizontal .control-label {
    padding-top: 9px;
    font-size: 12px;
  }
}

@media (min-width: @screen-md-min) {
  .form-inline-fullwidth {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: @box-padding;

    .form-group {
      display: block;

      .form-control {
        width: 100%;
      }
    }

    .btn {
      margin-top: 23px; // align vertically with inputs
      width: 100%;
    }
  }
}


