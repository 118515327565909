.noUi-marker-large, .noUi-marker-sub {
  background: @brand-primary;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 12px;
}

.noUi-marker-horizontal.noUi-marker {
  width: 1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 8px;
}

.noUi-value:not(.noUi-value-sub) {
  margin-top: 8px;
}

.noUi-value-sub {
  margin-top: 4px;
  font-size: 12px;
}

.noUi-value-horizontal {
  color: @text-color;
}

.noUi-tooltip {
  padding: 4px 8px;
  border: 1px solid @border-gray;
  border-radius: @border-radius-small;
  color: @text-color;
  font-weight: bold;
}

.noUi-connects {
  border-radius: @border-radius-small;
}

.noUi-target {
  background: @brand-gray;
  border: 1px solid @border-gray;
}

.noUi-handle {
  border: 1px solid @border-gray;
  border-radius: @border-radius-small;
  cursor: pointer;
  box-shadow: unset;
}
