@import (reference) "/assets/css/bootstrap/variables";

.reservation-rooms {
  table.table {
    border-collapse: separate;
    border-bottom: none;

    thead tr th {
      padding-left: 8px;
      padding-right: 8px;

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        width: 0;
        padding: 0 8px 0 5px;
      }
    }
  }

  tbody#room_details,
  tbody#Rlist1TableContent,
  tbody#Rlist2TableContent,
  tbody#Rlist3TableContent {
    tr {
      td {
        padding: @padding-small-vertical 8px;
        border-top: none;

        &:first-child {
          padding-left: 12px;
        }
      }

      &:hover {
        > td {
          background-color: unset;
        }
      }

      &:last-child > td {
        border-bottom: none;
      }
    }

    tr.reservation-rooms__room {
      td {
        &:first-child {
          border-left: 1px solid @border-gray;
        }

        &:last-child {
          border-right: 1px solid @border-gray;
        }
      }

      &:first-child td {
        border-top: 1px solid @border-gray;

        &:first-child {
          border-top-left-radius: @border-radius-small;
        }

        &:last-child {
          border-top-right-radius: @border-radius-small;
        }
      }

      &.select:hover {
        cursor: pointer;

        td {
          background-color: darken(@brand-gray, 3%);
        }
      }

      &.selected {
        td {
          background-color: lighten(@brand-success, 66%);
        }

        &:hover {
          td {
            background-color: lighten(@brand-success, 56%);
          }
        }

        &.row-highlight {
          td {
            background-color: lighten(@brand-danger, 46%);
          }

          &:hover {
            td {
              background-color: lighten(@brand-danger, 40%);
            }
          }
        }
      }

      .reservation-rooms__room-name {
        min-width: 80px;
      }

      .reservation-rooms__room-guests span {
        font-size: 12px;
      }

      .reservation-rooms__room-actions {
        text-align: right;

        button {
          width: 30px;
        }
      }

      .reservation-rooms__room-amount {
        padding-right: 0;
      }

      &.reservation-rooms__room--no-products.reservation-rooms__room--last {
        td {
          border-bottom: 1px solid @border-gray;

          &:first-child {
            border-bottom-left-radius: @border-radius-small;
          }

          &:last-child {
            border-bottom-right-radius: @border-radius-small;
          }
        }
      }
    }

    tr.reservation-rooms__service {
      td {
        &:first-child {
          border-left: 1px solid @border-gray;
        }

        &:last-child {
          border-right: 1px solid @border-gray;
        }
      }

      td.reservation-rooms__service-amount {
        padding-right: 0;
      }

      &.select:hover {
        cursor: pointer;

        td {
          background-color: darken(@brand-gray, 3%);
        }
      }

      &.selected {
        td {
          background-color: lighten(@brand-success, 66%);
        }

        &:hover {
          td {
            background-color: lighten(@brand-success, 56%);
          }
        }
      }
    }

    > tr.innerTable {
      > td {
        padding: 0;

        > table.table {
          background-color: unset;
        }
      }
    }

    tr.innerTable:first-child tr.reservation-rooms__service {
      td {
        border-top: 1px solid @border-gray;

        &:first-child {
          border-top-left-radius: @border-radius-small;
        }

        &:last-child {
          border-top-right-radius: @border-radius-small;
        }
      }
    }

    tr.reservation-rooms__room--last ~ tr.reservation-rooms__service--last,
    tr.reservation-rooms__room--last ~ tr.innerTable tr.reservation-rooms__service--last,
    tr.reservation-rooms__service--rooms-last {
      td {
        border-bottom: 1px solid @border-gray;

        &:first-child {
          border-bottom-left-radius: @border-radius-small;
        }

        &:last-child {
          border-bottom-right-radius: @border-radius-small;
        }
      }
    }

    tr.reservation-rooms__rooms-divider {
      td {
        height: auto;

        div {
          height: 1px;
          background-color: @border-gray;
        }

        &:first-child {
          border-left: 1px solid @border-gray;
        }

        &:last-child {
          border-right: 1px solid @border-gray;
        }
      }
    }

    tr.reservation-rooms__spacer {
      td {
        height: auto;
      }
    }

    tr.reservation-rooms__service--reservation {
      td {
        &:first-child {
          border-left: 1px solid @border-gray;
        }

        &:last-child {
          border-right: 1px solid @border-gray;
        }
      }

      &.reservation-rooms__service--first {
        td {
          border-top: 1px solid @border-gray;

          &:first-child {
            border-top-left-radius: @border-radius-small;
          }

          &:last-child {
            border-top-right-radius: @border-radius-small;
          }
        }
      }

      &.reservation-rooms__service--last {
        td {
          border-bottom: 1px solid @border-gray;

          &:first-child {
            border-bottom-left-radius: @border-radius-small;
          }

          &:last-child {
            border-bottom-right-radius: @border-radius-small;
          }
        }
      }
    }

    tr.deposit {
      td {
        &:first-child {
          padding-top: @padding-small-vertical;
          padding-bottom: 0;
          border-left: 1px solid @border-gray;
        }

        &:last-child {
          border-right: 1px solid @border-gray;
        }
      }

      .deposit-action {
        font-size: 22px;

        &:hover {
          cursor: pointer;
        }
      }

      .reservation-rooms__deposit-invoice {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 8px;

        a {
          display: flex;
        }
      }

      .reservation-rooms__deposit-amount {
        padding-right: 0;
      }

      &.select:hover {
        cursor: pointer;

        td {
          background-color: darken(@brand-gray, 3%);
        }
      }

      &.selected {
        td {
          background-color: lighten(@brand-success, 66%);
        }

        &:hover {
          td {
            background-color: lighten(@brand-success, 56%);
          }
        }
      }
    }

    tr.deposit.first {
      td {
        padding-top: 8px;
        border-top: 1px solid @border-gray;
      }

      td:first-child {
        padding-top: @padding-small-vertical;
        border-top-left-radius: @border-radius-small;
      }

      td:last-child {
        border-top-right-radius: @border-radius-small;
      }
    }

    tr.deposit.last {
      td {
        padding-bottom: 8px;
        border-bottom: 1px solid @border-gray;
      }

      td:first-child {
        padding-bottom: @padding-small-vertical;
        border-bottom-left-radius: @border-radius-small;
      }

      td:last-child {
        border-bottom-right-radius: @border-radius-small;
      }
    }

    tr td.reservation-rooms__room-invoiced-indicator {
      padding-left: 5px;
      padding-right: 5px;

      .invoiced-indicator {
        .status-indicator--invoiced;
      }
    }
  }
}

tbody#room_details,
tbody#room_summary {
  tr {
    td {
      padding: @padding-small-vertical 8px;
      border-top: none;
    }

    .room-details__summary-box--left {
      border-left: 1px solid @border-gray;
    }

    .room-details__summary-box--right {
      border-right: 1px solid @border-gray;
    }
  }

  tr.room-details__summary {
    td:last-child {
      padding-right: 12px;
    }

    th {
      border-top: none;
    }

    .room-details__summary-box--top-left {
      border-top: 1px solid @border-gray;
      border-left: 1px solid @border-gray;
      border-top-left-radius: @border-radius-small;
    }

    .room-details__summary-box--top-right {
      border-top: 1px solid @border-gray;
      border-right: 1px solid @border-gray;
      border-top-right-radius: @border-radius-small;
    }

    .room-details__summary-box--bottom-left {
      border-bottom: 1px solid @border-gray;
      border-left: 1px solid @border-gray;
      border-bottom-left-radius: @border-radius-small;
    }

    .room-details__summary-box--bottom-right {
      border-bottom: 1px solid @border-gray;
      border-right: 1px solid @border-gray;
      border-bottom-right-radius: @border-radius-small;
    }

    &:last-child {
      .room-details__summary-box--bottom-left,
      .room-details__summary-box--bottom-right {
        border-bottom: 1px solid @border-gray;
      }
    }
  }

  .room-details__summary-divider {
    td {
      height: 1px;
      padding-top: 0;
      padding-bottom: 0;

      &:nth-child(2), &:last-child {
        div {
          height: 1px;
          background-color: @border-gray;
        }
      }
    }

    & + tr td{
      padding-top: 10px;
    }
  }

  #reservationGross {
    th {
      padding: @padding-small-vertical 8px 10px;

      &:last-child {
        padding-right: 12px;
      }
    }
  }
}
