.bootstrap-switch {
  border-color: @border-gray;

  .bootstrap-switch-container {
    .bootstrap-switch-handle-on.bootstrap-switch-primary {
      background: @brand-primary;
      color: @text-light;
      font-weight: 600;
    }

    .bootstrap-switch-handle-off.bootstrap-switch-default {
      background: @brand-gray;
      color: @text-color;
      font-weight: 600;
    }
  }

  &.bootstrap-switch-focused {
    border-color: @brand-primary;
    outline: 1px solid @brand-primary;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
