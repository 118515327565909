.dashboard-page {
  .dashboard__forecast {
    .dashboard__forecast-today {
      margin: 0 8px 8px;
      border: 1px solid @border-gray;
      border-radius: @border-radius-large;

      .dashboard__today-title {
        margin-top: 0;
        margin-bottom: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid @border-gray;
        text-align: center;
      }

      .dashboard__today-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 8px 12px;
        text-align: center;

        > .dashboard__today-block-title {
          font-size: 30px;
          font-weight: 700;
          line-height: 1.25;
        }

        > .dashboard__today-block-helper {
          margin-top: 4px;
          font-size: 12px;
        }

        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .dashboard_organizer-box {
    margin-top: 12px;
    margin-bottom: 0;
    border: 2px solid transparent;
    border-top-width: 20px;
    overflow: hidden;

    .box-tools {
      input.edit-color {
        position: absolute;
        width: 0;
        height: 0;
        border: 1px solid transparent; // Safari fix (input needs to have some size)
        background: none;
        outline: none;
      }

      .edit-color-trigger {
        color: @text-icon;
        cursor: pointer;
        z-index: 1;
      }
    }
  }

  .dashboard__cashbook {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 20px;
    padding: 12px;
    border-radius: @border-radius-large;
    background-color: @brand-primary;

  .dashboard__cashbook-balance {
    color: @text-light;
    font-size: 30px;
    font-weight: 700;
    white-space: nowrap;
  }

    .btn {
      white-space: normal;
    }
  }
}

