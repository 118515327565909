@import (reference) "/assets/css/bootstrap/variables";

#links.drag-and-drop-documents {
  .document__title {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .document__body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  @media (min-width: 550px) and (max-width: 991px), (min-width: 1250px) {
    .document__body {
      flex-direction: row;
      align-items: start;
      gap: 12px;
    }
  }
}

