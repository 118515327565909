.support-header-placeholder {
    width: 100%;
    height: 36px;
}

.support-header {
    position: fixed;
    inset: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin: 0;
    background-color: @brand-danger;
    font-size: 15px;
    font-weight: 600;
    color: @text-light;
    z-index: 6; // above all content and left sidebar, but below popups

    .support-header__title {
        margin: 0;
    }

    &.support-header--read-only {
        background-color: @brand-success;
    }

    &.support-header--dashboard {
        background-color: @brand-primary;
    }
}
