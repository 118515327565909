.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex-grow: 1;
  display: grid;
  grid-template-areas: "header header"
                       "sidebar content"
                        "sidebar footer";
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 0 100%;
  transition: grid-template-columns 0.3s ease-in-out;

  .main-header {
    grid-area: header;
    z-index: unset;
  }

  .main-sidebar {
    grid-area: sidebar;
    position: static;
    width: 0;
    transform: translate(-240px, 0);
    z-index: unset;
  }

  .content-wrapper {
    grid-area: content;
    z-index: unset;

    section.content {
      padding: 8px 15px;
    }
  }

  .main-footer {
    grid-area: footer;
    background-color: @body-bg;
    text-align: center;
    color: @text-color;
    z-index: unset;
  }

  @media (min-width: @screen-sm) {
    grid-template-areas: "sidebar header"
                         "sidebar content"
                         "sidebar footer";
    grid-template-columns: 240px calc(100% - 240px);

    .main-header {
      .navbar {
        margin-left: 0;
      }
    }

    .main-sidebar {
      width: 240px;
      transform: translate(0, 0);
    }

    .content-wrapper {
      margin-left: 0;
    }

    .main-footer {
      margin-left: 0;
    }
  }
}

@media (max-width: @screen-sm) {
  .sidebar-open {
    .main-sidebar {
      width: 240px;
    }

    .content-wrapper {
      transform: translate(240px, 0);
    }

    .main-sidebar, .main-footer {
      transform: translate(0, 0);
    }
  }
}

@media (min-width: @screen-sm) {
    .sidebar-collapse {
      .app-container {
        grid-template-columns: 50px calc(100% - 50px);
      }

      &.sidebar-mini {
        .main-header .navbar {
          margin-left: 0;
        }

        .main-sidebar {
          z-index: 5; // show sidebar above content (which has so far max z-index of 3) and below tooltips (which have z-index of 10) + leave room for other elements to come in-between
        }

        .content-wrapper, .main-footer {
          margin-left: 0 !important;
          z-index: unset;
        }
      }
    }
}
