.sortable-list-default {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 6px 8px;
    border: @border-gray;
    border-radius: @border-radius-small;
    background: @brand-gray;
    cursor: pointer;
  }

  li + li {
    margin-top: 2px;
  }
}
