.login-page {
  background-color: @body-bg;

  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  min-height: 100vh;
  flex-wrap: nowrap;

  h1 {
    margin-top: 0;
    font-weight: bold;
  }
}

.login-page__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: floor(@grid-gutter-width / 2);

  .btn-login {
    .button-variant(@text-light, @brand-secondary, darken(@brand-secondary, 5%));

    &:focus {
      outline-color: white;
    }
  }
}

.login-page__cover {
  display: none;
  background-image: url('../../img/login/background.jpg');
  background-position: center center;
  background-size: cover;
}

.login-page--christmas {
  .login-page__cover {
    background-image: url('../../img/login/background_jolly.jpg');
    background-position: center right;
    background-color: #44547c;
  }

  button[type="submit"] {
    .button-variant(#fff; @red; darken(@red, 5%));
  }
}

@media (min-width: @screen-xs-min) {
  .login-page__content .box {
    width: 360px;
    margin: 0 auto;
  }
}

@media (min-width: @screen-sm-min) {
  .login-page__content {
    min-width: 500px;
  }

  .login-page__cover {
    display: flex;
    flex: 1;
  }
}
