.box {
  margin-bottom: 12px;

  .box-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 12px;
    height: auto;
    min-height: 40px;
    padding-top: 3px;
    padding-bottom: 3px;

    .box-title {
      align-self: center;
      margin: 0;
      font-weight: 700;
      font-size: 18px;
      color: @text-color;
    }

    .box-tools {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      > *:not(.btn.btn-default-clear) {
        margin-top: 4px;
      }

      .nav.nav-pills {
        justify-content: end;
      }
    }
  }

  &:not(&.collapsed-box) .hidden-box-open {
    display: none;
  }
}

// Remove ::before and ::after elements so that flex + gap doesn't create extra space (reverts bootstrap clearfix)
.box-header, .box-body {
  &:before, &:after {
    content: none;
  }
}

.box.box-solid > .box-header {
  > .box-tools .btn {
    border: 1px solid transparent;
  }

  .btn.btn-default {
    background-color: @brand-gray;
    border-color: darken(@brand-gray, 2%);
  }

  .btn.btn-default:hover, .nav.nav-pills > li:not(.active) > a:hover {
    background-color: darken(@brand-gray, 10%);
    border-color: darken(darken(@brand-gray, 2%), 12%);
  }

  .btn.btn-primary:hover {
    background-color: darken(@brand-primary, 10%);
    border-color: darken(darken(@brand-primary, 2%), 12%);
  }
}
