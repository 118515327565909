@import (reference) 'stacks.less';

.panel {
  margin-bottom: 0;

  .panel-heading {
    .hstack;
    align-items: start;
    justify-content: space-between;
    gap: 12px;
    border-bottom: none;

    .panel-title {
      font-size: 14px;
    }

    .panel-title-lg {
      font-size: 16px;
    }
  }

  .panel-body:first-child {
      padding-top: 8px;
  }
}

.panel.panel-default-clear {
  border: none;
  box-shadow: none;

  .panel-heading {
    padding-left: 0;
    padding-right: 0;
  }

  .panel-body {
    padding-left: 0;
    padding-right: 0;
  }
}
