.split-invoice-page {
  .table {
    tfoot.footer-without-borders {
      > tr {
        > th {
          padding-left: 8px;
          padding-right: 8px;
          border-top: none;
        }
      }
    }
  }

  .btn_rightscroll {
    position: absolute;
    top: 50px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .left_ab {
    left: -36px;
  }

  .right_ab {
    right: -36px;
  }

  .dropdown-menu {
    width: 100%;
    height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .dropdown img.icon {
    height: 22px;
    width: 40px;
    margin-right: 8px;
    border: none;
    vertical-align: middle;
    object-fit: contain;
  }

  .dropdown .invoice_payment_preview img.icon {
    width: auto;
  }

  .flagvisibility {
    display: none;
  }

  #room_details tr.selected, #Rlist1TableContent tr.selected, #Rlist2TableContent tr.selected, #Rlist3TableContent tr.selected {
    background: @brand-gray;
  }

  table.table #Rtotal th {
    padding: 4px 8px;
    border-top: none;
  }

  .splitPaymentData input[type=text] {
    line-height: 26px;
    width: 100%;
  }

  .splitPaymentData dl {
    margin-bottom: 3px;
  }

  section.disabled {
    color: #9a9da6;

    .box {
      background-color: @brand-gray;

      .box-header .box-title {
        color: #9a9da6;
      }
    }

    .select-custom.form-control,
    .splitPaymentData input[type=text] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  @media (min-width:992px) {
    .modal-xl {
      width: 960px
    }
  }

  .invoiced-items-header {
    display: flex;
    gap: 4px;
    align-items: center;
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: @brand-success;
  }
}
