.reservation-stripe {
  #cardList {
    .card {
      .reservation-stripe__content-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .card-number {
          text-align: center;
        }
      }

      .reservation-stripe__card-actions {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
      }

      @media (min-width: 450px) and (max-width: 992px), (min-width: 1100px) {
        .reservation-stripe__content-wrapper {
          gap: 12px;
        }

        .reservation-stripe__card-actions {
          flex-direction: row;
        }
      }
    }

    .card + .card {
      margin-top: 12px;
    }
  }
}
