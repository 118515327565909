@import (reference) "/assets/css/buttons";

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  border-radius: @border-radius-small;
  font-weight: 600;
  white-space: normal;

  a.btn {
    text-decoration: none;

    &.btn-default-clear {
      color: @text-color;
    }
  }

  .alert-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;

    h4, p {
      margin-bottom: 0;
    }
  }

  .alert-action-icon {
    .btn;
    width: 28px;
    padding: 2px;
    background-color: transparent;
    border: none;
    border-radius: @border-radius-small;
    color: @text-light;

    &:hover {
      background-color: #FFFFFF55;
      cursor: pointer;
    }

    i {
      font-size: 20px;
      line-height: 1;
    }
  }
}

.alert.alert-small {
  padding: @padding-base-vertical @padding-base-horizontal;
}

.alert-flash {
  position: absolute;
  inset: 0;
  z-index: 50;
  padding: 4px 15px 0;
}
