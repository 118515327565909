.main-header {
  height: 50px;

  .sidebar-toggle:before {
    font-family: 'Font Awesome 6 Pro';
  }

  .navbar {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    // Remove ::before and ::after elements so that flex + gap doesn't create extra space (reverts bootstrap clearfix)
    &:before, &:after {
      content: unset;
    }

    .customer-support-header {
      display: inline-block;
      padding: 8px;

      &:hover {
        background-color: darken(@body-bg, 4%);

        .customer-support-sidebar__icon {
          color: @text-icon-active;
        }
      }

      .customer-support-sidebar__icon {
        font-size: 16px;
        color: @text-icon;
      }
    }

    .navbar__utility-actions {
      display: flex;
      height: 50px;
      margin: 0;

      // Remove ::before and ::after elements so that flex + gap doesn't create extra space (reverts bootstrap clearfix)
      &:before, &:after {
        content: unset;
      }

      > * {
        height: 100%;
      }

      #organizer-notifications {
        .organizer-notifications__badge {
          position: absolute;
          top: 8px;
          right: 4px;
          padding: 2px 4px;
          font-size: 9px;
          line-height: 1;
        }

        .dropdown-menu {
          position: absolute;
          max-width: clamp(320px, 100%, 500px);
          background-color: white;
          box-shadow: @shadow-md;

          .organizer-notifications__list {
            max-height: 500px;
            overflow: auto;
            padding-top: 4px;
            padding-bottom: 4px;

            a {
              border: 1px solid @border-gray;
            }

            > a + a {
              margin-top: 4px;
            }

            .organizer-notifications__task-heading {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 8px;
              margin-bottom: 4px;

              .organizer-notifications__task-title {
                margin: 0;
                white-space: normal;
              }

              .organizer-notifications__task-time {
                white-space: nowrap;
                font-size: 11px;
              }
            }
          }
        }
      }

      .language-picker {
        .dropdown-menu {
          left: unset;

          > li {
            padding: 0;
          }
        }
      }

      .user.user-menu {
        .dropdown-menu {
          min-width: 250px;
          padding: 12px 24px;
        }

        .user__header {
          text-align: center;

          .user__avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            background-color: @brand-primary;
            color: @text-light;
            font-weight: 600;
          }

          .user__name {
            margin-top: 12px;
            font-size: 18px;
          }
        }

        .user__footer {
          display: flex;
          gap: 8px;
          margin-top: 20px;

          > * {
            flex: 1;
          }
        }
      }
    }
  }
}
