@import (reference) "/assets/css/widgets/calendar-toolbar-navigation";

.restrictions-setup-page {
  @cancellation-input-default-width: 85px;

  .calendar-toolbar {
    display: grid;
    grid-template-areas:
      "dates navigation"
      "visibility actions"
      "pages actions";
    grid-template-columns: minmax(150px, 220px) auto;
    column-gap: 8px;

    .calendar-toolbar__dates {
      grid-area: dates;
      display: inline-flex;
      align-self: start;

      input#set_date {
        min-width: 100px;
        max-width: 150px;
      }
    }

    .calendar-toolbar__visibility {
      grid-area: visibility;
      margin-bottom: 12px;
    }

    .calendar-toolbar__pages {
      grid-area: pages;
    }

    .calendar-toolbar__actions {
      grid-area: actions;
      display: flex;
      align-items: start;
      justify-content: end;
      flex-wrap: wrap;
      gap: 8px;
    }

    .calendar-toolbar-navigation {
      grid-area: navigation;
    }
  }

  @media (min-width: 500px) {
    .calendar-toolbar {
      grid-template-areas:
        "dates visibility navigation"
        "pages actions actions";
      grid-template-columns: minmax(150px, 220px) auto auto;
    }
  }

  @media (min-width: 1100px) {
    .form-group {
      margin-bottom: 0;
    }

    .calendar-toolbar {
      grid-template: "dates visibility pages actions navigation";
      grid-template-columns: minmax(150px, 250px) min-content auto auto max-content;

      .calendar-toolbar__visibility {
        margin-bottom: 0;
      }
    }
  }

  .restrictions-setup__table-wrapper {
    display: flex;
    align-items: start;

    > .restrictions-setup__table--left {
      background-color: white;
    }

    > .restrictions-setup__table--right {
      position: relative;
      left: -1px;
      background-color: white;
    }

    #restrictionGrid {
      user-select: none;
    }

    .rates-category-name {
      font-weight:bold;
      text-align: center;
      vertical-align: middle;
    }

    .dp-calendar__date {
      width: @cancellation-input-default-width;
    }

    .table input[type="checkbox"] + label span {
      display: inline-block;
      width: 19px;
      height: 19px;
      margin: 0;
      vertical-align: middle;
      cursor: pointer;
    }

    .special-periods-content__cell--selected {
      &:after {
        content: '';
        position: absolute;
        inset: 0 -1px 0 0;
        background: @brand-primary;
        opacity: 0.4;
      }
    }

    .special-period {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      height: 23px; // Matches room-rate-setup: eventHeight(25px) - margin(2 * 1px)
      margin: 1px;
      padding: 2px 4px;
      border-radius: @border-radius-small;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      text-overflow:ellipsis;
      overflow: hidden;
      cursor: pointer;
      z-index: 1;
    }

    .rates-table-body > thead > tr:last-child>td {
      border-bottom: 1px solid #ddd;
    }

    .rates-table-body > tbody > tr > td {
      height: 28px;
      line-height: 1.2;
    }

    .rates-table-body > tbody > tr > td,
    .rates-table-body > thead > tr > th {
      &.table-cell--weekend {
        background-color: lighten(@brand-warning, 25%);
      }

      &.table-cell--today {
        background-color: lighten(@brand-success, 66%);
      }
    }

    .restriction-options {
      height: 28px;
      width: 50%;
      padding: 4px 6px;
      white-space:nowrap;
      overflow: hidden;
    }

    .input-textbox {
      width: 100%;
      margin: 0px;
      padding: 0px;
      border: 0px;
      text-align: center;
      background-color: transparent;
    }

    .checkbox-cell {
      text-align: center;
      height: 28px;
      padding: 2px;

      .checkbox-cell__checked-cell-marker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: @border-radius-small;
        background-color: @brand-primary;
        color: @text-light;
        font-size: 12px;
        visibility: hidden;
      }

      input {
        width: 0;
        height: 0;
        display: none;
      }
      input:checked + .checkbox-cell__checked-cell-marker {
        visibility: visible;
      }
    }

    .selectbox-inside-cell{
      height: 24px;
      width: 100%;
      min-width: @cancellation-input-default-width - 4px * 2; // 4px padding on each side
      border: 0;
      padding: 0 4px;
      background-color: transparent;
      background-position: right 4px center;
    }

    /*
        Temporary style fixes to make it look like Rates matrix (until restrictions refactor comes along)
    */
    table.rates-table-body {
      border-collapse: collapse;
      border: 0; /* Prevents unnecessary borders */
    }
    table.rates-table-body thead th {
      border: 1px solid #eee; /* Table headers have bold borders */
    }
    table#restrictionGrid thead td,
    table#restrictionGrid tbody td {
      border: 1px solid #eee; /* Table body has less bold borders */
    }
    table.rates-table-body thead th,
    table.rates-table-body thead td {
      font-size: 12px;
    }
    table.rates-table-body thead td:not([rowspan]),
    table.rates-table-body thead th {
      vertical-align: middle; /* All headers centered, except category names */
    }
    table#restrictionGrid thead th {
      font-weight: 400; /* Table default is bold, we want non-bold */
    }
    table#restrictionGrid tbody td[data-date="<?= date('Y-m-d') ?>"],
    table#restrictionGrid tbody td[data-date="<?= date('Y-m-d') ?>"] input,
    table#restrictionGrid tbody td[data-date="<?= date('Y-m-d') ?>"] select {
      background-color: orange; /* highlight today */
    }
  }

  .restrictions-setup__table-notes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 12px 0 0 8px;
    font-size: 10px;
  }
}
