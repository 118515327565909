.close {
  float: unset;
  margin: 0;
  font-weight: 400;
  color: @text-color;
  text-shadow: unset;
  opacity: 0.5;

  &:hover,
  &:focus {
    color: @text-color;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
  }

  button& {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    padding: 6px;
    border-radius: @border-radius-base;
    cursor: pointer;

    &:hover, &:focus {
      background-color: #e6e6e6;
    }
  }
}
