.datepicker, .datetimepicker {
  font-size: 16px;

  .datepicker-days, .datetimepicker-days {
    table {
      td {
        padding: @padding-base-vertical @padding-base-horizontal;
      }
    }
  }

  .datetimepicker-hours, .datetimepicker-minutes {
    td > span {
      height: auto;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  table {
    tr {
      td.today {
        background-color: lighten(@brand-success, 66%);
        border-color: lighten(@brand-success, 66%);
        color: @text-color;
        background-image: none;
        text-shadow: none;

        &:active, &:focus, &:active:focus, &:hover, &:active:hover {
          background-color: lighten(@brand-success, 60%);
          border-color: lighten(@brand-success, 60%);
          color: @text-color;
        }
      }

      td.active:active,
      td.active.highlighted:active,
      td.active.active,
      td.active.highlighted.active,
      td span.active:active,
      td span.active:hover:active,
      td span.active.disabled:active,
      td span.active.disabled:hover:active,
      td span.active.active,
      td span.active:hover.active,
      td span.active.disabled.active,
      td span.active.disabled:hover.active {
        background-image: none;
        background-color: @brand-primary;
        color: @text-light;

        &:hover {
          background-color: darken(@brand-primary, 5%);
        }
      }

      td span.active.active:hover,
      td span.active:hover.active:hover,
      td span.active.disabled.active:hover,
      td span.active.disabled:hover.active:hover,
      td span.active:active:focus,
      td span.active:hover:active:focus,
      td span.active.disabled:active:focus,
      td span.active.disabled:hover:active:focus,
      td span.active.active:focus,
      td span.active:hover.active:focus,
      td span.active.disabled.active:focus,
      td span.active.disabled:hover.active:focus,
      td span.active:active.focus,
      td span.active:hover:active.focus,
      td span.active.disabled:active.focus,
      td span.active.disabled:hover:active.focus,
      td span.active.active.focus,
      td span.active:hover.active.focus,
      td span.active.disabled.active.focus,
      td span.active.disabled:hover.active.focus{
        background-color: darken(@brand-primary, 5%);
      }
    }
  }
}
