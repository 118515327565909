@import (reference) "/assets/css/variables";

::selection {
    background-color: #519dd6; // professional blue
    color: white;
}

body, h1, h2, h3, h4, h5, h6 {
    font-family: @font-family-sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}

dl {
    margin-bottom: 0;

    dd + dt {
        margin-top: 12px;
    }
}

textarea {
    resize: none;
}

i.fa.fa-info-circle.more-info {
    color: @text-icon;
    font-size: 12px;
}

hr[data-content] {
    position: relative;
    overflow: visible;
    margin-top: 12px;
    margin-bottom: 12px;

    &:after {
        content: attr(data-content);
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding-left: 8px;
        padding-right: 8px;
        color: @text-color;
        background-color: #fff;
    }
}

.required label.control-label:after {
    content:" * ";
    color: #a94442;
}

.pagination {
    margin: 0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.header--with-form {
    line-height: 32px;
}

.box--full-height {
    height: 100%;
}

.content-split {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.image-responsive {
    max-width: 100%;
}

.navbar-form {
    margin-bottom: 0;
}

.btn-label-spacing {
    margin-top: 20px;
}

.relative {
    position: relative;
}

.sp-dd {
    font-size: 9px !important; /* some stupid hack that works. Spent 2 hours figuring out why its not showing up inline anymore since the changes and I just dont care anymore. */
}

.card-number:before {
    content: 'xxxx xxxx xxxx ';
}

.property-selector {
    flex: 1;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;

    .property-selector__title {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dropdown-menu {
        width: 100%;
    }
}

.drag-and-drop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 16px;
    border: 1px dashed @input-color-placeholder;
    border-radius: @border-radius-base;

    span {
        font-size: 14px;
        font-weight: 700;
        color: @text-color;
    }

    i {
        font-size: 24px;
        color: darken(@brand-primary, 10%);
    }

    &.active {
        padding: 14px;
        background-color: @brand-gray;
        border: 3px dashed @brand-secondary;
    }
}

.less-padding{
    padding-right: 5px;
    padding-left: 5px;
}

@keyframes fadeOutUp {
    to {
        transform: translate3d(0, -30px, 0);
        opacity: 0;
    }
}

.daterangepicker .input-mini {
    border-radius: 0px !important;
    height: auto !important;
}

.daterangepicker .ranges li {
    border-radius: 0px !important;
}

.payment-icon {
  padding: 4px;
  font-size: 23px !important;
  line-height: 1;
  background: 0 0;
}

.ui-sortable-handle-organizer {
    border-left:2px solid;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px !important;
}

::-webkit-color-swatch {
    border: none;
}

.highlight-task{
    animation: highlight-task-animation 6s forwards;
}

@keyframes highlight-task-animation {
    20% {
        background-color: #e7a03c;
    }
    40% {
        background-color: #e7a03c;
    }
    100% {
        background-color: white;
    }
}

.fiskaly-container {
    display: flex;
    > img {
        flex: 1;
        flex-grow: 0;
        padding-right: @box-padding;
    }
    > table {
        flex: 1;
        th {
            width:1%;
            white-space:nowrap;
        }
    }
}

.pulse {
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(var(--bgColor), 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(var(--bgColor), 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(var(--bgColor), 0);
    }
}

@keyframes vertical-shaking {
    0% { transform: translateY(0) }
    25% { transform: translateY(5px) }
    50% { transform: translateY(-5px) }
    75% { transform: translateY(5px) }
    100% { transform: translateY(0) }
}

.vertical-shaking {
    animation: vertical-shaking 1s infinite;
}

.no-empty:empty {
    display: none !important;
}

i.fa {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    :before {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

hr {
    width: 100%;
}

/* Simple tooltip that doesn't mess up theme display like the one from bootstrap or JQuery.
    To use add ".tooltip-here" to some object, and then add a span with ".tooltip-text" tooltip text right after it.  */
.tooltip-here {
    position: relative;
    display: inline-block;

    .tooltip-text {
        visibility: hidden;
        position: absolute;
        padding: 4px 6px;
        border-radius: @border-radius-small;
        background-color: @brand-primary;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: @text-light;
        z-index: 10;
        transform: translateZ(0); // safari 16.1+ does not render tooltips correctly. Using this does *something* that fixes it
    }

    &.tooltip-top .tooltip-text {
        top: -4px;
        left: 50%;
        margin-bottom: 4px;
        transform: translate(-50%, -100%) translateZ(0)
    }

    &.tooltip-bottom .tooltip-text {
        top: calc(100% + 4px);
        left: 50%;
        margin-top: 4px;
        transform: translate(-50%, 0) translateZ(0);
    }
}

@media not all and (pointer:coarse) { /* no tooltip on touch devices */
    .tooltip-here:hover .tooltip-text {
        visibility: visible;
    }
}

@media (min-width: @screen-sm-min) {
    .pull-sm-right {
        float: right !important;
    }
    .pull-sm-left {
        float: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-left {
        text-align: left !important;
    }
}

@media (min-width: @screen-md-min) {
    .pull-md-right {
        float: right !important;
    }
    .pull-md-left {
        float: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }
    .text-md-left {
        text-align: left !important;
    }
}

@media (min-width: @screen-lg-min) {
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-left {
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    .row.equal {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display:         flex;
    }
}

@media (max-width: 767px) {
    .btn-block-xs {
        display: block;
        width: 100%;
    }

    .btn-group-justified-xs {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate
    }
    .btn-group-justified-xs>.btn,
    .btn-group-justified-xs>.btn-group {
        display: table-cell;
        float: none;
        width: 1%
    }
    .btn-group-justified-xs>.btn-group .btn {
        width: 100%
    }
    .btn-group-justified-xs>.btn-group .dropdown-menu {
        left: auto
    }
}

@media (max-width: 991px) {
    .btn-group-justified-md {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate
    }
    .btn-group-justified-md>.btn,
    .btn-group-justified-md>.btn-group {
        display: table-cell;
        float: none;
        width: 1%
    }
    .btn-group-justified-md>.btn-group .btn {
        width: 100%
    }
    .btn-group-justified-md>.btn-group .dropdown-menu {
        left: auto
    }
}

@media (min-width: 992px) {
    .row-md--with-divider {
        display: flex;
        flex-direction: row;
    }

    .row-md--with-divider > .divider {
        margin: 15px 0;
        border-left: 1px dashed #ccc;
    }
}

.log-content-cell {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
