@import (reference) "~bootstrap/less/panels";

.damage-panel {
  .panel();
  .panel-default();
  margin-bottom: revert; // Remove margin-bottom from .panel
  padding: 8px;
  overflow: hidden; // During "resolve" animation content can overflow
}

.damage-media {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;

  > img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
  }
}

.damage-heading {
  font-size: @font-size-h4;
}
.damage-heading-meta {
  font-size: @font-size-h5;
  opacity: 0.65
}

.damage-description, .damage-media, .damage-action {
  margin-top: 8px;
}
