// READ-ONLY
// Idk. This was appended to the previous adminlte file. Should figure out
// where these styles are used and slowly get rid of this file.

.bx_nor {
  width: 50px;
  float: left;
  margin-right: 5px;
}
.bx_big {
  width: 65px;
  float: left;
  margin-right: 5px;
}
.bx_bigest {
  width: 68px;
  margin-right: 3px;
  text-align: center;
  display: inline-table;
}

/* .squaredFour */
.squaredOne, .squaredTwo, .squaredThree, .squaredFour, .squaredFive, .squaredSix, .squaredSeven, .squaredEight {
  width: 40px;
  position: relative;
  margin: 0px auto;
  bottom: -8px;
}
.squaredOne label, .squaredTwo label, .squaredThree label, .squaredFour label, .squaredFive label, .squaredSix label, .squaredSeven label, .squaredEight label {
  cursor: pointer;
  height: 9px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}
.squaredOne label:after, .squaredTwo label:after, .squaredThree label:after, .squaredFour label:after, .squaredFive label:after, .squaredSix label:after, .squaredSeven label:after, .squaredEight label:after {
  content: '';
  width: 14px;
  height: 7px;
  position: absolute;
  top: -1px;
  left: 15px;
  border: 4px solid #7eb823;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.squaredOne label:hover::after, .squaredTwo label:hover::after, .squaredThree label:hover::after, .squaredFour label:hover::after, .squaredFive label:hover::after, .squaredSix label:hover::after, .squaredSeven label:hover::after, .squaredEight label:hover::after {
  opacity: 0.5;
}
.squaredOne input[type=checkbox], .squaredTwo input[type=checkbox], .squaredThree input[type=checkbox], .squaredFour input[type=checkbox], .squaredFive input[type=checkbox], .squaredSix input[type=checkbox], .squaredSeven input[type=checkbox], .squaredEight input[type=checkbox] {
  visibility: hidden;
}
.squaredOne input[type=checkbox]:checked + label:after, .squaredTwo input[type=checkbox]:checked + label:after, .squaredFour input[type=checkbox]:checked + label:after, .squaredThree input[type=checkbox]:checked + label:after, .squaredFive input[type=checkbox]:checked + label:after, .squaredSix input[type=checkbox]:checked + label:after, .squaredSeven input[type=checkbox]:checked + label:after, .squaredEight input[type=checkbox]:checked + label:after {
  opacity: 1;
}
/* end .squaredFour */

body .ondisplay section:after {
  /*         visibility: hidden; */
  content: attr(title);
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px #000;
}
/*** Add room poop up window..***/
.guest_type > input {
  width: 23%;
}
/*** Add room poop up window end***/
/*** Add room poop up window..***/
.guest_type > input {
  width: 23%;
}
/*** Add room poop up window end***/
.form_left {
  float: left;
  margin-right: 10px;
  width: 13%;
}
.form_left1 {
  float: left;
  width: 7%;
}
.form_left2 {
  float: left;
  margin-right: 10px;
  width: 16%;
}
.form_left3 {
  float: left;
  margin-right: 5px;
  width: 25%;
}
.add_line {
  float: left;
  width: 50px;
  margin-right: 6px;
}
#myform {
  /* border: 1px dotted #ccc;*/
  float: left;
  margin: 2% 2% 2% 25%;
  padding: 5px;
  width: 50px;
}

.bx_bigest1 li {
  float: left;
  margin-right: 3px;
  text-align: center;
  width: auto;
  margin-bottom: 20px;
  list-style: none;
}
.bx_bigest1 {
  margin: 0 !important;
  padding: 0 !important;
}
.arrow_box, .arrow_box1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 8px 0 8px;
  border-color: #12c742 transparent transparent;
  position: absolute;
  right: 20px;
}
.arrow_box {
  top: 49px;
}
.arrow_box1 {
  top: 56px;
}
.arrow_box_top, .arrow_box_top1 {
  width: 0;
  position: absolute;
  height: 0;
  border-style: solid;
  border-width: 0 8px 20px 8px;
  border-color: transparent transparent #12c742;
  right: 20px;
}
.arrow_box_top {
  top: 25px;
}
.arrow_box_top1 {
  top: 35px;
}
/*anna css*/
.total_box {
  background: #ececec none repeat scroll 0 0;
  border: 1px solid #dddddd;
  bottom:10px;
  line-height: 10px;
  padding: 10px;
  position: absolute;
  right: -59px;
  text-align: right;
  width: 75px;
}
.line_mid {
  line-height: 40px;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #DDD;
  }
}
.preview-ul {
  float: left;
  width: 70%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.preview-li {
  float: left;
  width: 20%;
}
.preview-li-colon {
  float: left;
  width: 1%;
}
.reservation-email-preview .mailbox-read-message {
  padding: 10px;
  float: left;
  width: 100%;
  border-top: 1px solid #f4f4f4;
  margin: 9px 0 0 0;
  overflow: scroll;
}
#update_details .dropdown-menu.iconvalue > li {
  border: 1px solid #eee;
  float: left;
  padding: 10px;
  text-align: center;
  width: 50px;
  background: #fff;
}
.box-width {
  width: 20%;
}
/* popover code */
.font_norl {
  font-size: 13px;
  padding: 5px 0px;
  line-height: 30px;
  height: 40px;
}
.font_norl a {
  font-size: 13px;
  color: #212121;
  text-decoration: none;
}
.font_norl a:hover {
  font-size: 13px;
  color: #cccccc;
  text-decoration: none;
}
/*
 * Invoice template
 * */
.bot_hr hr {
  margin-bottom: 5px;
  margin-top: 5px;
  border-color: #cccccc !important;
}
.bot_hr h4 {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.act-heading h3 {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.product_discount_up {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10px 6px;
  border-color: #999 transparent #999;
  position: absolute;
  right: 20px;
  top: 31px;
}
.product_discount_down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 6px 0 6px;
  border-color: #999 transparent transparent;
  position: absolute;
  right: 20px;
  top: 43px;
}
.text_sort .btn {
  line-height: 13px;
  padding: 0;
}
.bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger {
  background-color: transparent
}
/*indexcss*/
.indexcss.box-body {
  padding: 15px;
}
.indexcss.add-new {
  float: right;
  margin-bottom: 10px;
}
.indexcss td:hover {
  background: #ccc;
}
.indexcss table.table.table-condensed.dataTable td {
  line-height: 1;
  margin: 0;
}
#all_bgtop .box-header{ padding:10px 10px 10px 0px;}
.sidebar-menu [class^="flaticon-"]::before, .sidebar-menu [class*=" flaticon-"]::before, .sidebar-menu [class^="flaticon-"]::after, .sidebar-menu [class*=" flaticon-"]::after {
  font-family: Flaticon;
  font-size: 14px;
  font-style: normal;
  margin-left:0px;}
#input_all h4{ margin-bottom:5px;margin-top:5px; }
#circle-outer .img-circle{ width:45px; height:45px; background:#607d8b !important; font-size:20px; color:#fff; text-align:center; line-height:45px;  left: 2px;position: absolute;}
.bg h5 { height:32px;
  overflow: hidden; margin:2px 2px;
  word-wrap: break-word;
}
.bg{
  background-image: -webkit-linear-gradient(top, #DBDBDB, #EDEDED);
  background-image: -moz-linear-gradient(top, #DBDBDB, #EDEDED);
  background-image: -ms-linear-gradient(top, #DBDBDB, #EDEDED);
  background-image: -o-linear-gradient(top, #DBDBDB, #EDEDED);
  background-image: linear-gradient(to bottom, #DBDBDB, #EDEDED);
  border-radius: 5px;
  padding:8px 6px 5px 6px;
  min-height: 70px;
  cursor: pointer;

}
#top_align2 .box-header {padding:0px 10px 10px 10px; }
#sales_map .Legend_inner { width:100%;}
#sales_map .Legendcen { width:30%; margin:0 auto;}

#sales_map .Legend div{
  margin-left:8px;
  width:12px; line-height:12px;
  border:0px solid #808080;
  display:inline-block;
}
#sales_map .ie7 .Legend div{
  display:inline;
  zoom:1;
}
#sales_map .reserved {background-color:#c1c7d1;}
#sales_map .invoiced {background-color:#3b8bba;}

#kvFileInputError{
  color: #dd4b39;
  background-color: initial;
  margin: 0px;
  border: none;
  border-radius: initial;
  padding: 0px 0px 15px 0px;
}
