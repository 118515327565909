.kv-editable {
  display: block;

  .kv-editable-link {
    border-bottom: none;
    color: @text-color;
    text-align: left;
  }
}

.kv-editable-form {
  > .panel {
    border: none;
    margin: 0;
    box-shadow: none;
  }

  > .card {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 0;
    border:none;
  }
}

.kv-editable-parent.form-group {
  display: inline-block;
}

.kv-editable-content .form-group {
  margin: 0;
}

