#update_reservn {
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;

    .calendar-form__left-section,
    .calendar-form__right-section{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .calendar-form__main-settings {
      .calendar-form__settings-section {
        .calendar-form__room_required {
          display: flex;
          gap: 8px;
          width: 100%;
        }

        .calendar-form__arrival-departure,
        .calendar-form__adults-children1,
        .calendar-form__children2-free {
          display: flex;
          gap: 8px;

          > * {
            width: 100%;
            min-width: 60px;
          }
        }

        .calendar-form__children2-free {
          > .form-group {
            margin-bottom: 0;
          }
        }

        @media (min-width: @screen-sm-min) {
          display: flex;
          gap: 6px;
          align-items: stretch;

          > * {
            flex: 1;
          }

          .calendar-form__room-status {
            flex: 0.8;
          }

          .calendar-form__room_required {
            flex: 1.3;
          }

          .calendar-form__arrival-departure {
            flex: 3
          }

          .calendar-form__children2-free {
            > .form-group {
              margin-bottom: 12px;
            }
          }
        }
      }

      @media (min-width: 1250px) {
        display: flex;
        gap: 6px;

        .calendar-form__settings-section {
          flex: 1;

          > .form-group {
            margin-bottom: 0;
          }

          .calendar-form__room_required,
          .calendar-form__arrival-departure,
          .calendar-form__adults-children1,
          .calendar-form__children2-free {
            > .form-group {
              margin-bottom: 0;
            }
          }

          .calendar-form__arrival-departure {
            > * {
              flex: 1;
              width: 140px;
            }
          }

          .calendar-form__adults-children1,
          .calendar-form__children2-free {
            > * {
              min-width: 45px;
            }
          }
        }
      }
    }

    .calendar-form__vat-discounts {
      margin-top: 8px;
    }

    .calendar-form__checkin,
    .calendar-form__checkout {
      display: flex;
      align-items: center;
      gap: 6px;

      > label {
        width: 90px;
        font-weight: 400;

        i {
          width: 15px;
          font-size: 16px;
        }
      }

      #checkin-details,
      #checkout-details {
        flex: 1;
      }
    }

    .calendar-form__checkin {
      margin-bottom: 8px;
    }

    #incl-product-list {
      .drop_down_list {
        margin-top: 12px;
      }
    }

    #description-tabs {
      .tab-content {
        textarea.form-control {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          color: @text-color;
        }
      }
    }

    .calendar-form__salto {
      margin-top: 12px;

      .calendar-form__salto-header {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        background-color: @brand-gray;
        border-radius: @border-radius-base;

        .calendar-form__salto-tags-key {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-left: auto;
        }
      }

      .calendar-form__salto-body {
        margin-top: 12px;

        .calendar-form__salto-pin {
          display: flex;
          align-items: stretch;
          flex-direction: column;
          gap: 4px;

          > p {
            i {
              width: 20px;
              font-size: 16px;
            }
          }

          #saltoPinButtons {
            display: flex;
            gap: 8px;
            margin-top: 4px;
          }
        }

        #saltoPinCodeContainer {
          margin-top: 12px;
        }
      }
    }

    .client_meal_preferences,
    .client_stay_preferences {
      display: flex;
      align-items: start;
      gap: 8px;
      flex-wrap: wrap;
    }

    @media (min-width: 1250px) {
      display: grid;
      grid-template-areas:
        "settings settings"
        "left right";
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);

      > * {
        height: max-content;
      }

      .calendar-form__main-settings {
        grid-area: settings;
      }

      .calendar-form__left-section {
        grid-area: left;
      }

      .calendar-form__right-section {
        grid-area: right;
      }

      .calendar-form__arriving-guests {
        grid-area: guests;
        display: flex;
        gap: 12px;
      }

      .calendar-form__pricing-taxes {
        grid-area: money;
        display: flex;
        gap: 8px;
        align-items: start;

        .room-pricing-block {
          flex: 1;
        }

        .calendar-form__vat-discounts {
          width: 150px;
          margin-top: 0;
        }
      }
    }
  }
}

