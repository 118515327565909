.arriving-guests-field {
  width: 100%;

  .arriving-guests-field__guests {
    display: flex;
    align-items: start;
    gap: 8px;

    .edit-guest {
      &.edit-guest-selected {
        background-color: darken(@brand-gray, 10%);
      }
    }
  }

  .arriving-guests-field__guest-form {
    margin-top: 8px;

    .arriving-guests-field__guest-fields {
      width: 100%;
    }

    .arriving-guests-field__form-actions {
      display: flex;
      gap: 8px;

      > * {
        width: 100%;
      }
    }

    @media (min-width: 500px) {
      .arriving-guests-field__guest-fields {
        display: flex;
        gap: 8px;

        > *:not(:first-child) {
          flex: 1;
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      display: flex;
      gap: 12px;

      .arriving-guests-field__guest-fields {
        > .form-group {
          margin-bottom: 0;
        }
      }

      .arriving-guests-field__form-actions {
        align-items: end;
      }
    }
  }
}
