@import (reference) "/assets/css/widgets/calendar-toolbar-navigation";

.offer-calendar-page {
  .calendar-toolbar {
    display: grid;
    grid-template-areas:
      "dates navigation"
      "categories zoom"
      "actions actions";
    column-gap: 8px;

    .calendar-toolbar__dates {
      grid-area: dates;
      display: inline-flex;
      align-self: start;

      input#filter_date {
        min-width: 100px;
        max-width: 150px;
      }
    }

    #categoryFilter {
      grid-area: categories;
    }

    .calendar-toolbar__zoom {
      grid-area: zoom;
      display: flex;
      justify-content: end;
      gap: 8px;
    }

    .calendar-toolbar__actions {
      grid-area: actions;
    }

    .calendar-toolbar-navigation {
      grid-area: navigation;
    }
  }

  @media (min-width: 650px) {
    .calendar-toolbar {
      grid-template-areas:
        "dates categories zoom navigation"
        "actions actions actions actions";
      grid-template-columns: minmax(150px, 220px) min-content auto auto;

      .calendar-toolbar__zoom {
        justify-content: start;
      }
    }
  }

  @media (min-width: 767px) {
    .calendar-toolbar {
      grid-template-areas:
        "dates navigation"
        "categories zoom"
        "actions actions";
      grid-template-columns: auto auto;

      .calendar-toolbar__zoom {
        justify-content: end;
      }
    }
  }

  @media (min-width: 992px) {
    .calendar-toolbar {
      grid-template:
        "dates categories zoom navigation"
        "actions actions actions actions";
      grid-template-columns: minmax(150px, 270px) min-content auto auto;

      .calendar-toolbar__zoom {
        justify-content: start;
      }
    }
  }

  @media (min-width: 1200px) {
    .form-group {
      margin-bottom: 0;
    }

    .calendar-toolbar {
      grid-template: "dates categories zoom actions navigation";
      grid-template-columns: minmax(150px, 270px) min-content max-content auto max-content;
    }
  }
}
