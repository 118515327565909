// Hijacked from bootstrap5
// @see https://github.com/twbs/bootstrap/blob/aee169d19288d78330be2df0960ea8924353f84f/scss/helpers/_stacks.scss

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
