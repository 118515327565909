.scheduler_default_main .btn {
  box-sizing: border-box;
}

.dp-calendar__date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.dp-calendar__date--weekend {
    background-color: lighten(@brand-warning, 25%);
  }

  &.dp-calendar__date--today {
    background-color: lighten(@brand-success, 66%);
  }

  &.dp-calendar__date--narrow {
    gap: 4px;
  }

  .dp-calendar__date-week {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }

  .dp-calendar__date-day {
    font-weight: 600;
  }

  .dp-calendar__date-number {
    font-weight: 700;
    font-size: 22px;

    &.dp-calendar__date-number--narrow {
      font-size: 18px;
    }
  }

  .dp-calendar__date-month {
    color: lighten(@text-color, 25%);
    font-weight: 600;
    text-transform: uppercase;
  }
}

div#dp, div#dp-spaced {
  border-radius: @border-radius-large;
  font-family: @font-family-sans-serif;

  .scheduler_default_corner {
    border-top-left-radius: @border-radius-large;
  }

  .scheduler_default_rowheader_scroll {
    border-bottom-left-radius: @border-radius-large;
  }

  .scheduler_default_divider.scheduler_default_splitter + div > div {
    border-top-right-radius: @border-radius-large;
  }

  .scheduler_default_rowheader_inner,
  .scheduler_default_event_inner,
  .scheduler_default_cell {
    display: flex;
    align-items: center;
  }

  .scheduler_default_cell {
    justify-content: center;

    > input {
      background: transparent;
      border: none;
      width: 90%;
      text-align: center;
    }
  }

  .scheduler_default_columnheader_cell {
    display: inline-flex !important;
    align-items: center;

    .scheduler_default_columnheader_cell_inner {
      padding: 0;

      &:not(:empty) {
        margin-left: 8px;
      }
    }
  }

  .scheduler_default_rowheader {
    .scheduler_default_rowheader_inner {
      padding: 0 8px;

      .rm-pty-img-spn {
        margin-right: 0;

        &:not(:first-child) {
          margin-left: 4px;

        }
      }

      .scheduler_default_tree_image_expand, .scheduler_default_tree_image_collapse {
        background: none;

        &:before {
          font-family: "Font Awesome 6 Pro";
        }
      }

      .scheduler_default_tree_image_expand:before {
        content: '\f078';
      }

      .scheduler_default_tree_image_collapse:before {
        content: '\f077';
      }

      .scheduler_default_tree_image_no_children {
        background-image: none;
      }

      .scheduler_default_tree_image_expand + div,
      .scheduler_default_tree_image_collapse + div,
      .scheduler_default_tree_image_no_children + div {
        margin-left: 8px;
      }
    }

    &.second-level .scheduler_default_rowheader_inner {
      .scheduler_default_tree_image_expand + div,
      .scheduler_default_tree_image_collapse + div,
      .scheduler_default_tree_image_no_children + div {
        margin-left: 28px;
      }
    }

    &.third-level .scheduler_default_rowheader_inner {
      .scheduler_default_tree_image_expand + div,
      .scheduler_default_tree_image_collapse + div,
      .scheduler_default_tree_image_no_children + div {
        margin-left: 48px;
      }
    }
  }

  .scheduler_default_timeheadercol_inner {
    border-color: lighten(@border-gray, 5%);
    color: @text-color;
  }

  .scheduler_default_timeheader_float {
    display: none;
  }

  .scheduler_default_event, .scheduler_default_event_float {
    .scheduler_default_event_inner, .scheduler_default_event_float_inner {
      gap: 8px;
      margin: 1px;
      padding-left: 4px !important;
      padding-right: 4px !important;
      border-radius: @border-radius-small;
      border: none;
      font-weight: 600;

      .reservation__content {
        display: flex;
        justify-content: end;
        gap: 2px;
        width: 100%;
        z-index: 1;

        .reservation__guests-info {
          flex: 1;
          overflow: hidden;
        }

        .reservation__status-icons {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 4px;
        }
      }
    }

    .scheduler_default_event_float_inner {
      position: relative;
      display: flex;
      align-items: center;
      height: calc(100% - 6px); // 6px = 1px * 2 (margin) + 2px * 2 (padding)
      padding: 2px 4px 2px 8px !important; // spacing based on principle - least amount but elements are still noticeably separated

      &:after {
        top: calc(50% - 5px);
        border-right-color: @brand-primary;
      }
    }


    &.scheduler_default_event_continueleft {
      .scheduler_default_event_inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.scheduler_default_event_continueright {
      .scheduler_default_event_inner {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.event--full-size {
      .scheduler_default_event_inner {
        margin: 0 1px;
        border-radius: 0;
      }
    }
  }

  .event--align-center .scheduler_default_event_inner {
    justify-content: center;
  }
}

#dp .scheduler_default_matrix_vertical_line {
  background-color: unset;
  border-right: none;
  border-left: 1px solid #eee;
}

#dp-spaced .scheduler_default_matrix_vertical_line {
  width: 0 !important;
}

.bubble_default_main {
  .bubble_default_main_inner {
    padding: 8px;
    color: @text-color;
    background: white;
    border-color: @border-gray;
    border-radius: @border-radius-base;
    box-shadow: @shadow-md;

    .dp-calendar__bubble {
      .dp-calendar__bubble-table {
        border-collapse: separate;
        width: 100%;

        tr {
          td {
            padding: 2px 6px;
          }
        }

        .dp-calendar__bubble-room:not(:first-child) {
          > td {
            border-top: 1px solid @border-gray;
          }
        }
      }
    }
  }
}
