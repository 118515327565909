.flex {
  display: flex !important;
}

.flex-md {
  @media (min-width: @screen-md-min) {
    display: flex !important;
  }
}

.inline-flex {
  display: inline-flex !important;
}

.inline {
  display: inline !important;
}

.flex-col {
  flex-direction: column !important;
}

.justify-start {
  justify-content: start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.items-start {
  align-items: start !important;
}

.items-center {
  align-items: center !important;
}

.items-stretch {
  align-items: stretch !important;
}

.items-end {
  align-items: end !important;
}

.block {
  display: block !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 1 0 !important;
}

.flex-3 {
  flex: 3 1 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-start {
  align-self: start !important;
}
.align-self-end {
  align-self: end !important;
}

.grow {
  flex-grow: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.float-none {
  float: none !important;
}

.w-full {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-inherit {
  width: inherit !important;
}

.h-full {
  height: 100% !important;
}

each(range(0, 5, 1), {
  .m-@{value} {
    margin: (@value * 4px) !important;
  }

  .my-@{value} {
    margin-top: (@value * 4px) !important;
    margin-bottom: (@value * 4px) !important;
  }

  .mx-@{value} {
    margin-left: (@value * 4px) !important;
    margin-right: (@value * 4px) !important;
  }

  .ml-@{value} {
    margin-left: (@value * 4px) !important;
  }

  .mt-@{value} {
    margin-top: (@value * 4px) !important;
  }

  .mr-@{value} {
    margin-right: (@value * 4px) !important;
  }

  .mb-@{value} {
    margin-bottom: (@value * 4px) !important;
  }

  .p-@{value} {
    padding: (@value * 4px) !important;
  }

  .py-@{value} {
    padding-top: (@value * 4px) !important;
    padding-bottom: (@value * 4px) !important;
  }

  .px-@{value} {
    padding-left: (@value * 4px) !important;
    padding-right: (@value * 4px) !important;
  }

  .pl-@{value} {
    padding-left: (@value * 4px) !important;
  }

  .pt-@{value} {
    padding-top: (@value * 4px) !important;
  }

  .pr-@{value} {
    padding-right: (@value * 4px) !important;
  }

  .pb-@{value} {
    padding-bottom: (@value * 4px) !important;
  }

  .gap-@{value} {
    gap: (@value * 4px) !important;
  }
});

.last-mb-0 {
  > :last-child {
    margin-bottom: 0 !important;
  }
}

.font-size-h1 {
  font-size: @font-size-h1;
}

.font-size-h2 {
  font-size: @font-size-h2;
}

.font-size-h3 {
  font-size: @font-size-h3;
}

.font-size-h4 {
  font-size: @font-size-h4;
}

.font-size-h5 {
  font-size: @font-size-h5;
}

.font-size-h6 {
  font-size: @font-size-h6;
}

.font-size-10 {
  font-size: 10px !important;
  line-height: 14px !important;
}

.font-size-12 {
  font-size: 12px !important;
  line-height: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
  line-height: 20px !important;
}

.font-size-16 {
  font-size: 16px !important;
  line-height: 24px !important;
}

.text-light {
  color: #FCFCFC !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.text-muted {
  opacity: 0.65 !important;
}

.text-danger {
  color: @brand-danger !important;
}

.text-success {
  color: @brand-success !important;
}

.text-gray {
  color: @text-gray !important;
}

.text-dark-gray {
  color: @brand-dark-gray !important;
}

.text-gray-lighten-20 {
  color: lighten(@text-gray, 20%) !important;
}

.hover-text-body {
  &:hover {
      color: @text-color !important;
  }
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.overflow-auto {
  overflow: auto !important;
}

.text-right {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-body {
  color: @text-color !important;
}

.underline {
  text-decoration: underline !important;
}

.italic {
  font-style: italic !important;
}

.normal {
  font-weight: normal !important;
}

.relative {
  position: relative !important;
}

.bg-white {
  background-color: white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-secondary {
  background-color: @brand-secondary !important;
}

.bg-dark-blue {
  background-color: #0000ff !important;
}

.bg-success {
  background-color: @brand-success !important;
}

.bg-warning {
  background-color: @brand-warning !important;
}

.bg-danger {
  background-color: @brand-danger !important;
}

.bg-dark-gray {
  background-color: @brand-dark-gray !important;
}

.border-none {
  border: none !important;
}

.border {
  border-width: 1px !important;
  border-style: solid !important;
}

.border-right {
  border-right-width: 1px !important;
  border-right-style: solid !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vam {
  vertical-align: middle !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.no-br {
  white-space: nowrap !important;
}

.rounded-t-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-sm {
  border-radius: @border-radius-small !important;
}

.border-success {
  border-color: @brand-success !important;

  &.bg-success {
    border-color: darken(@brand-success, 5%) !important;
  }
}

.border-warning {
  border-color: @brand-warning !important;

  &.bg-warning {
    border-color: darken(@brand-warning, 5%) !important;
  }
}

.border-danger {
  border-color: @brand-danger !important;

  &.bg-danger {
    border-color: darken(@brand-danger, 5%) !important;
  }
}

.border-info {
  border-color: @brand-primary !important;

  &.bg-info {
    border-color: darken(@brand-primary, 5%) !important;
  }
}

.border-dark-blue {
  border-color: #0000ff !important;

  &.bg-dark-blue {
    border-color: darken(#0000ff, 5%) !important;
  }
}

.border-gray {
  border-color: @border-gray !important;
}

.word-break {
  word-break: break-word !important;
}

.shadow-md {
    box-shadow: @shadow-md !important;
}
