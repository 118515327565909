@import (reference) "forms";

.table {
  border-collapse: separate;
  margin-bottom: 0;

  &:not(:has(> tfoot)) {
    border-bottom: 1px solid @border-gray;
  }

  > thead {
    > tr {
      > th {
        padding-left: 12px;
        padding-right: 12px;
        border-bottom: none;
        font-weight: 600;
        font-size: 12px;
        vertical-align: middle;

        a {
          color: @text-color;
          text-decoration: underline;
          text-decoration-style: dashed;
          text-underline-offset: 2px;
        }

        > i.more-info {
          color: @text-icon;
          font-size: 12px;
        }
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding-left: 12px;
        padding-right: 12px;
        vertical-align: middle;

        &.cell-with-status-indicator {
          line-height: 0;
          font-size: 16px;
        }

        &.cell-with-limited-width {
          > div, > a > div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.cell-with-actions {
          width: 1%;
          padding: 0;
          line-height: 1;
          text-align: left;
          white-space: nowrap;

          .btn-link {
            height: 28px; // default table row height
            width: 32px; // sets unified reasonable width for all action buttons

            .caret {
              display: none;
            }
          }
        }
      }

      &.row-group-header {
        font-weight: bold;

        > td {
          padding-left: 4px;
          font-size: 10px;
        }

        &:first-child > td {
          border-top: none;
        }

        &:not(:first-child) > td {
          padding-top: 16px;
        }
      }

      &.vertical-align-start {
        > td {
          vertical-align: top;
        }
      }

      &.warning {
        > td {
          background-color: lighten(@brand-warning, 25%);
        }
      }

      &.success, &.row-selected {
        > td {
          background-color: lighten(@brand-success, 66%);
        }
      }

      &.success.row-muted {
        > td.skip-muted {
          background-color: rgba(rgb(lighten(@brand-success, 66%), 0.65));
        }
      }

      &.danger {
        > td {
          background-color: lighten(@brand-danger, 46%);
        }
      }

      &.info {
        > td {
          background-color: @brand-gray;
        }
      }
    }

    > tr.row-muted {
      > td:not(.skip-muted) {
        opacity: 0.65;
      }
    }
  }

  > tfoot {
    > tr {
      > th {
        padding-left: 12px;
        padding-right: 12px;
        font-weight: 600;
        vertical-align: middle;
      }

      &.warning {
        > th {
          background-color: lighten(@brand-warning, 25%);
        }
      }
    }
  }
}

div.table-responsive {
  min-height: unset;
  margin-bottom: 0;
}

table.table.table-hover {
  > tbody {
    > tr {
      > td:not(.cell-with-actions) {
        > a {
          min-height: calc((@font-size-base * @line-height-base) + (@padding-small-vertical * 2));
          display: block;
          margin: -4px -12px;
          padding: @padding-small-vertical 12px;
        }

        a {
          color: @text-color;
        }
      }
    }

    > tr:hover:not(.row-group-header), tr.hover {
      cursor: pointer;

      > td {
        background-color: darken(@brand-gray, 3%);
      }
    }

    > tr.row-group-header:hover {
      background-color: white;
    }
  }
}

.table:not(.table-wrap) {
    > tbody {
        > tr {
          > td {
            white-space: nowrap;
          }
        }
    }
}

.kv-grid-table {
  input[type="checkbox"] {
    .input-checkbox;
    position: relative;
  }
}

.table.detail-view {
  border-bottom: none;

  > tbody {
    > tr:first-child {
      > th, > td {
        border-top: none;
      }
    }
  }
}

.table tr td .progress {
  margin-top: 0;
}
