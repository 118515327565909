@import (reference) "/assets/css/variables";

.dropdown-menu {
  padding: 4px;
  border-color: @border-gray;
  border-radius: @border-radius-large;
  box-shadow: @shadow-lg;

  > li {
    margin: 0;

    > a {
      color: @text-color;
      padding: 6px 8px;
      border-radius: @border-radius-small;
      cursor: pointer;

      &:hover {
        color: @text-color;
        background-color: @brand-gray;
      }

      > i:first-child {
        margin-right: 4px;
      }
    }

    &.divider {
      margin-top: 4px;
      margin-bottom: 4px;
      background-color: @border-gray;
    }
  }

  > li.active {
    > a {
      color: @text-light;

      &:hover {
        color: @text-light;
        background-color: @brand-primary;
      }
    }
  }

  > li.form-group {
    width: 100%;

    .checkbox {
      width: 100%;

      & > a {
        display: flex;
        padding: 8px;
        border-radius: @border-radius-small;

        label {
          white-space: nowrap;
        }

        &:hover {
          background-color: @brand-gray;
        }
      }
    }
  }

  > li.dropdown-submenu {
    position: relative;

    & > a:after {
      content: "";
      position: absolute;
      right: 4px;
      transform: translateY(50%);
      border-color: transparent;
      border-style: solid;
      border-width: 5px 0 5px 5px;
      border-left-color: @text-color;
    }

    & > .dropdown-menu {
      top: 0;
    }
  }
}

.dropdown-menu.dropdown-menu-condensed {
  > li {
    > a {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}

.navbar-nav > .user-menu {
  > .dropdown-menu {
    > li.user-header {
      height: auto;
    }
  }
}

.dropdown-menu:has(.dropdown-menu-primary-action) {
  // To not allow content to peek under the primary action
  padding-bottom: 0;
}

.dropdown-menu-primary-action {
  position: sticky;
  bottom: -1px; // 0 doesn't fully cover content on scroll
  padding: 0 8px 8px;
  background-color: white;

  hr {
    margin: 0 0 8px 0;
  }
}

.visible-column-menu {
  // 340px is  @pre-scrollable-max-height value.
  // In quotes because less pre-processor doesnt support native css `max` function
  max-height: ~"max(50vh, 340px)";
  overflow-y: auto;
}
