@import (reference) "/assets/css/forms";

.input-group {
  .input-group-addon {
    border-color: @border-gray;
    cursor: pointer;

    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid @border-gray;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    > i.fa {
      min-width: 14px;
    }

    input[type="checkbox"] {
      .input-checkbox;
    }
  }

  .form-control {
    z-index: auto;

    &:focus {
      z-index: auto;
    }
  }

  .input-group-addon:last-child {
    .checkbox {
      line-height: 20px;
    }
  }

  .input-group-btn {
    .btn-default {
      border: 1px solid @border-gray;
    }
  }
}
