.guest-table-page {
  .guest-table__toolbar {
    display: grid;
    grid-column-gap: 8px;
    grid-template-areas: "date reg-back"
                          "row check";

    .guest-table__date {
      grid-area: date;
    }

    .guest-table__check {
      grid-area: check;
      justify-self: end;
    }

    .guest-table__reg-back {
      grid-area: reg-back;
      display: flex;
      align-items: start;
      justify-content: end;
      gap: 8px;
    }
  }

  @media (min-width: @screen-md-min) {
    .guest-table__toolbar {
      grid-template-areas: "date row check reg-back";
      grid-template-columns: auto max-content min-content 1fr;

      .guest-table__date {
        margin-bottom: 0;
      }
    }
  }
}
