@import (reference) "stacks";

.btn-group {
  display: inline-flex;

  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: 1px;
  }
}

.btn-ribbon {
  .hstack;
  gap: 8px;
  flex-wrap: wrap;
}

.btn-details-list {
  display: flex;
  flex-direction: column;
  align-items: start;

  >:nth-child(even) {
    margin-bottom: 12px;
  }

  @media (min-width: @screen-sm-min) {
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 8px;
    row-gap: 12px;
    align-items: center;

    >:nth-child(even) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

