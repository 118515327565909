#room_reservn {
  .add-rooms__toolbar {
    .add-rooms__start-date {
      grid-area: start;
    }

    .add-rooms__end-date {
      grid-area: end;
    }

    .add-rooms__filter {
      grid-area: filter;
    }

    .add-rooms__occupied {
      grid-area: occupied;
    }

    @media (min-width: @screen-sm-min) {
      display: grid;
      grid-template-areas: "start end"
                            "filter occupied";
      column-gap: 12px;

      .add-rooms__occupied {
        margin-top: 30px;
      }
    }

    @media (min-width: @screen-md-min) {
      grid-template-areas: "start end filter occupied";
      grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) 1fr auto;
    }
  }

  .add-rooms__tables {
    flex: 1;
    display: grid;
    overflow: hidden;

    > .table-responsive {
      overflow-y: auto; // fixes vertical scroll on mobile screen
    }

    table > tbody > tr:nth-child(even) > td {
        border-top: none; // hide border when adding or editing guest
    }

    #occupied_room_details {
      overflow: auto;

      h4 {
        position: sticky;
        top: 0;
        margin-bottom: 0;
        padding-bottom: 8px;
        background-color: white;
        z-index: 1;
      }
    }
  }
}
