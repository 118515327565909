#product_service_details {
  .edit-product-service__type-selection {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 12px;
    overflow-x: auto;
  }

  .edit-product-service__translations {
    .tab-content {
      textarea.form-control {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
