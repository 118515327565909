ul.task-list-column {
  margin: 0;

  li.organizer-task {
    display: flex;
    margin: 12px 0 0 0;
    padding: 0;
    border-radius: @border-radius-small;
    border-left: none;
    box-shadow: @shadow-md;

    .organizer-task__left-border {
      width: 8px;
      border-radius: @border-radius-small 0 0 @border-radius-small;
    }

    .organizer-task__main {
      flex-grow: 1;
      width: 100%;

      > * {
        margin-left: 12px;
        margin-right: 12px;
      }

      > .organizer-task__main-content {
        margin-top: 12px;

        p.organizer-task__title {
          margin: 0;
          font-size: 14px;
          font-weight: 700;
        }

        p.organizer-task__content {
          margin-top: 8px;
          margin-bottom: 0;
          font-size: 14px;
          white-space: break-spaces;
        }

        p.organizer-task__linked-reservation {
          margin: 8px 0 0;
          text-transform: capitalize;
        }
      }

      .organizer-task__comments-button {
        margin-top: 8px;
        width: calc(100% - 24px);
      }

      .organizer-task__comments {
        .direct-chat-msg {
          margin-top: 20px;

          &:not(:first-child) {
            margin-top: 16px;
            margin-bottom: 16px;
          }

          .direct-chat-header {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            font-size: 12px;

            .direct-chat-timestamp {
              color: @text-gray;
            }
          }

          .direct-chat-content {
            display: flex;
            gap: 4px;
            margin: 4px 0 0;

            .direct-chat-text {
              flex: 1;
              display: flex;
              margin: 0 0 0 4px;
              padding: 4px 4px 4px 8px;
              border-radius: @border-radius-base;
              border: none;
              background-color: @body-bg;

              &:before, &:after {
                border-right-color: @body-bg;
              }

              p {
                flex: 1;
                margin: 0;
                color: @text-color;
                word-break: break-word;
              }

              .delete-comment-button {
                align-self: start;
                font-size: 16px;
              }
            }
          }
        }

        .direct-chat-msg.direct-chat-msg__me {
          .direct-chat-header {
            flex-direction: row-reverse;
          }

          .direct-chat-content {
            flex-direction: row-reverse;

            .direct-chat-text {
              margin: 0 4px 0 0;

              &:before, &:after {
                border-right: none;
                border-left-color: @body-bg;
                left: unset;
                right: -5px;
              }
            }
          }
        }

        div.form-group {
          margin-top: 12px;
          margin-bottom: 0;

          div.input-group {
            .add-comment-input {
              font-weight: 400;
            }
          }
        }
      }

      .organizer-task__footer {
        margin: 10px 8px 8px;
        padding: 10px 8px 0;
        border: 1px solid @border-gray;
        border-radius: @border-radius-base;

        .organizer-task__user-avatar {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 0;

          .organizer-task__user-details {
            p {
              margin: 0;
            }

            p.organizer-task__created-by {
              font-size: 10px;
            }

            p.organizer-task__username {
              font-weight: 600;
            }
          }
        }

        div.organizer-task__details-actions {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-top: 4px;
          border-top: 1px solid @border-gray;

          .organizer-task__timestamp {
            margin: 0 auto 0 0;
          }

          .edit-task-button, .delete-task-button {
            font-size: 18px;
          }
        }
      }

      span.initials-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 10px;
        border-radius: 50%;
        text-align: center;
        background-color: @brand-primary;
        color: @text-light;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
}
