.daterangepicker {
  .ranges {
    ul {
      li.active {
        background-color: @brand-primary;
      }
    }
  }

  .drp-calendar {
    .calendar-table {
      .in-range {
        background-color: @brand-gray;
      }

      .start-date, .end-date {
        background-color: @brand-primary;
      }
    }
  }

  .drp-buttons {
    .drp-selected {
      color: @text-color;
      font-weight: 600;
    }
  }
}

.kv-drp-dropdown .kv-clear {
  padding: @padding-base-vertical @padding-base-horizontal;
  font-size: @font-size-base;
  right: unset;
  line-height: 1;
}
