.form-email-action-group {
  margin-top: 12px;

  .email-container {
    input.automailer-email {
      border-top-left-radius: 0;
    }
  }

  button.automailer-send {
    border-top-right-radius: 0;
  }
}

.automailer-widget {
  display: block;

  .automailer-email-template {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 6px 12px;
    font-size: 12px;
    border: 1px solid @border-gray;
    border-bottom: none;
    border-top-right-radius: @border-radius-base;
    border-bottom-left-radius: 0;

    label {
      .automailer-template-name {
        width: 100%;
        overflow-x: clip;
        text-overflow: ellipsis;
        line-height: 20px;
      }
    }
  }

  .form-email-action-group {
    margin-top: 0;

    .email-container > input.form-control {
      border-top-left-radius: 0;
    }
  }
}

@media (min-width: 500px) and (max-width: 991px), (min-width: 1400px) {
  .automailer-widget {
    display: flex;

    .automailer-email-template {
      max-width: 160px;
      border-right: none;
      border-bottom: 1px solid @border-gray;
      border-top-right-radius: 0;
      border-bottom-left-radius: @border-radius-base;
    }

    .form-email-action-group {
      .email-container{
        input.automailer-email {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      button.automailer-send {
        border-top-right-radius: @border-radius-small;
      }
    }
  }
}
